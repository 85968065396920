import React from "react";
import { Fragment } from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import "../../assets/scss/pages/lead.css";
import { useNavigate } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loan from "../../assets/images/loan.png";
import insurance from "../../assets/images/insurance.png";
import realEstate from "../../assets/images/real-estate.png";
import editIcon from "../../assets/images/editIcon.png";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import LoanCase from "../loanCases/loancase/LoanCase";
export default function ViewLead(LeadData) {
  const toggle = () => setModal(!modal);
  const [modal, setModal] = React.useState(false);
  const navigate = useNavigate();
  const navigateToCreateLead = () => {
    // 👇️ navigate to /CreateLead
    navigate("/LeadCreation-Lead");
  };

  const [leadData, setLeadData] = useState({
    userVO: {
      userId: "",
      converLead: false,
    },
    template:{
      templateId:'',
      templateName:'',
    }
  });
useEffect(()=>{
  loadTemplate();
},[]);
const[template,setTemplate]=useState();
  const loadTemplate = async () => {
    const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllTaskTemplate`);
   setTemplate(result.data)
  };

  const { LeadID } = useParams();
  console.log(LeadID);
  const [viewLead, SetViewLead] = useState({});
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getLeadById?leadID=${LeadID}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setLeadData(response.data);
        SetViewLead(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LeadID]);

  console.log("viewlead", viewLead);

  const [leadOwners, setLeadOwners] = useState([]);
  useEffect(() => {
    const d6 = 'https://pradhanportal.com/loanApplication/master/getAllUsers';
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);

        setLeadOwners(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigateToUpdateLead = () => {
    navigate(`/UpdateLead/${LeadID}`);
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    console.log("leadData", leadData);
    axios
      .put("https://pradhanportal.com/loanApplication/master/editLead", leadData)
      .then((response) => {
        console.log(response);
        ConvertLead();
      })
      .catch((err) => {
        console.error(err);
      });
      
  };

  const ConvertLead = () => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/convertLead?leadID=${LeadID}`;
    axios
      .put(dataUrl)
      .then((response) => {
        console.log("response :", response.data);

        setLeadData(response.data);
        navigate(`/loanCases`);
        toast.success("Lead Converted successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if(leadData.code === 200){
          <LoanCase LeadData={leadData}/>
        }else{
          console.error("Error in converting lead");
        }
      })
      .catch((err) => {
        console.log(err);
      });
   
  };

  const handleChange = (e) => {
    setLeadData({
      ...leadData,
      userVO: {
        userId: e.target.value,
      },
    });
  };
  const handleChangeForTemplate=(e)=>{
    setLeadData({
      ...leadData,
      template: {
        templateId: e.target.value,
      },
    });
  }
  const createdOn = viewLead.createdDate;
  const createdDateOn = moment(createdOn).format("DD MMM YYYY");
  var renderTooltip = <span>Edit Lead</span>;

  const authUser = JSON.parse(localStorage.getItem("user"));
  const obj=authUser?.role?.role;
  const roleId=authUser?.role?.roleId;

  const [privilegedata, setPrivilegeData] = useState([]);

  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllRolePrivilegeFlagsByRoleId?roleId=${roleId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setPrivilegeData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    
    <Fragment>
      {/* <pre>{JSON.stringify(leadData)}</pre> */}
      <div className="container-fluid ">
        <div className="card">
          {/* <div className="card-header"> */}
            <div className="row mt-3 mb-2">
              <div className="col-md-6">
                <span id="heading"> {viewLead.customerName}</span>{" "}
                <span className="mx-3 ">
                  {viewLead.customerTypeVO?.customerType}
                </span>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-2">
                {
                  privilegedata[2]?.privilegeId === 103 && privilegedata[2]?.privilageFlag === true?
                  <Fragment>
 <Tooltip placement="right" overlay={renderTooltip}>
                  <h6 id="viewLeadEditIcon">
                    <img
                    style={{marginRight:"20px"}}
                      src={editIcon}
                      width="24"
                      height="19"
                      alt=""
                      className=""
                      onClick={navigateToUpdateLead}
                    />
                  </h6>
                </Tooltip>
                  </Fragment>:null
                }
               
              </div>
            </div>
          {/* </div> */}
          <div className="row">
            <div className="col-md-6">
              <div className="card" id="card1">
                <div className="card-header" id="heading2">
                  Lead Details{" "}
                  {viewLead.type === "Loan" ? (
                    <span>
                      {" "}
                      <img
                        src={loan}
                        width="40"
                        height="30"
                        alt=""
                        className=""
                      />
                    </span>
                  ) : null}
                  {viewLead.type === "Insurance" ? (
                    <span>
                      {" "}
                      <img
                        src={insurance}
                        width="40"
                        height="30"
                        alt=""
                        className=""
                      />
                    </span>
                  ) : null}
                  {viewLead.type === "Real Estate" ? (
                    <span>
                      {" "}
                      <img
                        src={realEstate}
                        width="40"
                        height="30"
                        alt=""
                        className=""
                      />
                    </span>
                  ) : null}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <span className="d-flex justify-content-end">
                        Lead ID
                      </span>
                    </div>
                    <div className="col-md-9">
                      <span className=" text-white px-1" style={{backgroundColor:"#FDB714"}}>
                        {" "}
                        {viewLead.id}{" "}
                      </span>
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Created On
                      </span>
                    </div>
                    <div className="col-md-7 mt-2">{createdDateOn}</div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">Branch</span>
                    </div>
                    <div className="col-md-7 mt-2">
                      {viewLead?.initiatorVO?.branchVO?.branch}
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Lead Type
                      </span>
                    </div>
                    <div className="col-md-7 mt-2">
                      {viewLead.typesVO?.leadTypeVO?.leadType} -{" "}
                      {viewLead?.typesVO?.type}
                    </div>
                    {/* <div className="col-md-3 mt-2">
                   <span className="d-flex justify-content-end">Loan Type</span>
                </div>
                <div className="col-md-7 mt-2">
                {viewLead.loanType}
                </div> */}
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Loan Amount
                      </span>
                    </div>
                    <div className="col-md-7 mt-2">
                      <span className=" text-white px-1" style={{backgroundColor:"#FDB714"}}>
                        {" "}
                        ₹ {viewLead.amount}
                      </span>
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Initiated By
                      </span>
                    </div>
                    <div className="col-md-7 mt-2">
                      {viewLead?.initiatorVO?.initiatorName}
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Lead Owner
                      </span>
                    </div>
                    <div className="col-md-7 mt-2">
                      {viewLead?.userVO?.userName}
                    </div>
                    <div className="col-md-3 mt-2">
                      <span className="d-flex justify-content-end">
                        Lead Source
                      </span>
                    </div>
                    <div className="col-md-7 mt-2">
                      <span className=" text-white px-1" style={{backgroundColor:"#FDB714"}}>
                        {" "}
                        {viewLead?.leadSourceVO?.leadSource}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-1"></div> */}
            <div className="col-md-6">
              <div className="card" id="card2">
                <div className="card-header" id="heading2">
                  Contact Details
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5 mt-2">
                      <span className="d-flex justify-content-end">
                        Primary Mobile Number
                      </span>
                    </div>
                    <div className="col-md-7">
                    {viewLead.primaryNumber !== "" ? viewLead.primaryNumber:viewLead.contactPerson1stNumber}
                      <span className="mx-2" id="viewLeadWhatsapp">
                        <i class="bi bi-whatsapp"></i>
                      </span>
                    </div>
                    <div className="col-md-5 mt-2">
                      <span className="d-flex justify-content-end">
                        Secondary Mobile Number
                      </span>
                    </div>
                    <div className="col-md-7 mt-2">
                      {viewLead.secondaryNumber !== "" ? viewLead.secondaryNumber:viewLead.contactPerson2ndNumber}
                    </div>
                    <div className="col-md-5 mt-2">
                      <span className="d-flex justify-content-end">
                        Email ID
                      </span>
                    </div>
                    <div className="col-md-7 mt-2">
                    {viewLead.emailID !== "" ? viewLead.emailID:viewLead.contactPersonEmail}
                    </div>
                  </div>
                  {viewLead.customerType === "Non Individual" ? (
                    <Fragment>
                      <div className="row">
                        <div className="col-md-5 mt-2">
                          <span className="d-flex justify-content-end">
                            Contact Person
                          </span>
                        </div>
                        <div className="col-md-7 mt-2">
                          {viewLead.customerName}
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                  {viewLead.customerContactDetail === "Add Seprately" ? (
                    <Fragment>
                      <div className="row">
                        <div className="col-md-5 mt-2">
                          <span className="d-flex justify-content-end">
                            Customer Mobile No
                          </span>
                        </div>
                        <div className="col-md-7 mt-1">
                          {viewLead.customerMobileNo}
                          <span className="mx-2" id="whatsapp">
                            <i class="bi bi-whatsapp"></i>
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <span className="d-flex justify-content-end">
                            Customer 2nd Mobile No
                          </span>
                        </div>
                        <div className="col-md-7 mt-2">
                          {viewLead.customer2ndMoNo}
                        </div>
                        <div className="col-md-5 mt-2">
                          <span className="d-flex justify-content-end">
                            Customer Email ID
                          </span>
                        </div>
                        <div className="col-md-7 mt-2">
                          {viewLead.contactEmail}
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-4">
              <div className="card" id="card3">
                <div className="card-header" id="heading2">
                  Log Notes
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">{viewLead.leadNotes}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-1"></div> */}
            <div className="col-md-6 mt-4">
              <div className="card" id="card4">
                <div className="card-header" id="heading2">
                  General Discussion{" "}
                  {viewLead.gdStatusVO?.gdStatus === "Completed" ? (
                    <Fragment>
                      <span className="mx-3 completeStatus px-1">
                        {viewLead.gdStatusVO?.gdStatus}
                      </span>
                    </Fragment>
                  ) : viewLead.gdStatusVO?.gdStatus === "In Progress" ?
                  (
                    <Fragment>
                      <span className="mx-3 inprogressStatus px-1">
                        {viewLead.gdStatusVO?.gdStatus}
                      </span>
                    </Fragment>
                  ):(
                    <Fragment>
                    <span className="mx-3 pendingStatus px-1">
                      {viewLead.gdStatusVO?.gdStatus}
                    </span>
                  </Fragment>
                  )}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">GD Link</div>

                    <div className="col-md-10 mx-5 mt-2">
                      <a href={viewLead.gdLink}target="_blank">{viewLead.gdLink}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div className="row mt-3 mb-3">
              <div className="col-md-12">
                <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                  <button
                    onClick={navigateToCreateLead}
                    class="btn btn-secondary"
                    id="editbtn"
                    type="button"
                  >
                    Back
                  </button>

                  {authUser.role?.role === "Sales Executive" ||
                  authUser.role?.role === "Sales Manager" ? (
                    <Fragment></Fragment>
                  ) : (
                    <Fragment>
                      <button
                        onClick={toggle}
                        class="btn btn-primary"
                        type="button"
                        id="editbtn"
                      >
                        Convert Lead
                      </button>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          <Modal fade={true} isOpen={modal}  toggle={toggle} centered={true}>
          <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
              <span id="assignowner1">Convert Lead</span>
            </ModalHeader>
            <ModalBody className="py-3 px-5">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end">
                            Customer Name
                          </span>
                        </div>
                        <div className="col-md-8 mt-3">
                          {viewLead.customerName}
                        </div>
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end">
                            Lead Type
                          </span>
                        </div>
                        <div className="col-md-8 mt-3">
                          {viewLead.typesVO?.leadTypeVO?.leadType} -{" "}
                          {viewLead?.typesVO?.type}
                        </div>
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end">
                            Lead Owner
                          </span>
                        </div>
                        <div className="col-md-8 mt-3">
                          {viewLead?.ownerVO?.ownerName}
                        </div>
                        <div className="col-md-4 mt-4">
                          <span className="d-flex justify-content-end  ">
                            Select Task Tmplt
                          </span>
                        </div>
                        <div className="col-md-8 mt-3">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="template.templateId"
                            onChange= {handleChangeForTemplate}
                            value={leadData.template?.templateId}
                          >
                            <option selected>Please Select </option>
                            {template?.length > 0 ? (
                              <Fragment>
                                {template.map((temp, index) => {
                                  return (
                                    <option key={index} value={temp.templateId}>
                                      {temp.templateName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Executive Name
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="userVO.userId"
                            onChange={handleChange}
                            value={leadData.userVO?.userId}
                          >
                            <option selected>Please Select Owner</option>
                            {leadOwners?.length > 0 ? (
                              <Fragment>
                                {leadOwners.map((owner, index) => {
                                  return (
                                    <option key={index} value={owner.userId}>
                                      {owner.userName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-12 mt-4">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                            <Button
                              color="secondary"
                              id="btn-cancel"
                              onClick={toggle}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="primary"
                              type="submit"
                              id="btn-cancel"
                            >
                              Convert Lead
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </Fragment>
  );
}
