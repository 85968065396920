import React, { Fragment} from "react";
import { Link} from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {Col,Container,Row,Card,CardHeader,CardBody,Input} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
import "../../assets/scss/pages/lead.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import { Pagination } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import moment from 'moment';
import excelIcon from "../../assets/images/excel.png";
import * as XLSX from "xlsx";
export default function Lead() {
  const toggle = () => setModal(!modal);
  const [modal, setModal] = React.useState(false);
  var renderTooltip = <span>Create Lead</span>;
  var renderTooltipexcel = <span>Export Data</span>;
  const navigate = useNavigate();
  const navigateToCreateLead = () => {
    navigate("/AddLead");
  };

  const completeJD = () => {
    console.log("Complete GD")
    toast.error("Error! Complete the General Discussion First", {
      position:"top-right",
      className: "toast-message",
    });
  };
  const [filterLeads, setFilterLeads] = useState([]);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const[dataAll,setDataAll]=useState([]);

  const loadLead = async (value) => {
    const pageSize = value ? value : showRecord;
    if (value != null) {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllLeads?pageNumber=`+page+`&pageSize=`+pageSize+`&searchInput=`+search)
      setDataAll(result.data);
      console.log("getListOfAllCases",result.data)
    } else {
      const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllLeads?pageNumber=`+page+`&pageSize=`+pageSize+`&searchInput=`+search)
      setDataAll(result.data);
      console.log("getListOfAllCases",result.data)
    }
  };
  const[leads,setLeads]=useState([])
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllLoanCases`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setLeads(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [owner, setOwner] = useState([]);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllOwner`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);

        setOwner(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadLead();
  }, [search]);
  
  const [showRecord, setshowRecord] = useState(10);

  const  handleChanegeShowRec = (e) => {
    const { name, value, type, checked } = e.currentTarget;
 
    setshowRecord(e.currentTarget.value);

    loadLead(value);
   }
  //for table records per page
  const [recordCount, setrecordCount] = useState(10);
 
 
  //for pagination
 
  const [pageData, setPageData] = useState([]);
 const [page, setPage] = useState(1);
 const [pageCount, setPageCount] = useState(10);
 // handle next
 const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1)
 }
 
 // handle previous
 const handlePrevios = () => {
    if (page === 1) return page;
    setPage(page - 1)
 }
 
 useEffect(() => {
  loadLead()
 }, [page])
 
 useEffect(() => {
    const pagedatacount = Math.ceil(leads?.length / showRecord);
    setPageCount(pagedatacount);
 
    if (page) {
        const LIMIT = showRecord;
        const skip = LIMIT * page // 5 *2 = 10
         const dataskip = dataAll?.slice(page === 1 ? 0 : skip - LIMIT, skip);
        setPageData(dataskip)
    }
 }, [search,dataAll])
  useEffect(()=>{
    const result= dataAll.filter((item)=>{
     return item.customerName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setDataAll(result);
},[search]);

  const NavigateTOAssignModal = (leadID) => {
    toggle();
    fetchLeadData(leadID);
  };

  const [user, setUser] = useState({});
    useEffect(() => {
      const d6 = 'https://pradhanportal.com/loanApplication/master/getAllUsers';
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setUser(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

  const [viewLead, SetViewLead] = useState({
    userVO: {
      userId: "",
    },
  });

  const fetchLeadData = async (leadID) => {
    const response = await axios.get(
      `https://pradhanportal.com/loanApplication/master/getLeadById?leadID=${leadID}`
    );
    console.log(response.data);
    SetViewLead({ ...response.data });
    console.log("gg", viewLead);
  };

  console.log("viewlead", viewLead);

  const gdStatus=(dataAll)=>{
   return(
    dataAll.gdStatusVO?.gdStatus !== "Pending"  && dataAll.gdStatusVO?.gdStatus !== "In Progress" ? <Fragment>
    <div className="completeStatus px-1">
     {dataAll.gdStatusVO?.gdStatus}
    </div>
</Fragment>:dataAll.gdStatusVO?.gdStatus !== "Pending" && dataAll.gdStatusVO?.gdStatus !== "Completed" ?
<Fragment>
   <div className="inprogressStatus px-1">
     {dataAll.gdStatusVO?.gdStatus}
    </div>
</Fragment>:<Fragment>
   <div className="pendingStatus px-1">
     {dataAll.gdStatusVO?.gdStatus}
    </div>
</Fragment>
   )
  }
  const leadAgeing=(dataAll)=>{
return(
  dataAll.days < 1 && dataAll.hours < 1 ? (
    <Fragment>
      <span className="px-1" id="leadAge1">
        {" "}
        {dataAll.minutes} Minutes ago
      </span>
    </Fragment>
  ) : dataAll.days < 1 &&
  dataAll.hours < 4 &&
  dataAll.minutes > 60 ? (
    <Fragment>
      <span className="px-1" id="leadAge1">
        {" "}
        {dataAll.hours} Hours ago
      </span>
    </Fragment>
  ) : dataAll.days < 1 &&
  dataAll.hours >= 4 ? (
    <Fragment>
      <span className=" px-1" id="leadAge2">
        {" "}
        {dataAll.hours} Hours ago
      </span>
    </Fragment>
  ) : (
    <Fragment>
      <span className=" px-1" id="leadAge3">
        {" "}
        {dataAll.days} Days ago
      </span>
    </Fragment>
  )
)
  }
const leadOwner=(dataAll)=>{
  return(
    dataAll.userVO?.userName === null ||
      (dataAll.userVO?.userName === undefined &&
        dataAll.gdStatusVO?.gdStatus !== "Pending") ? (
        <Fragment>
          {
            privilegedata[1]?.privilegeId === 103 && privilegedata[1]?.privilageFlag === true?<Fragment>
<Link
          
          onClick={() =>
            NavigateTOAssignModal(dataAll.leadID)
          }
          className="disabledCursor"
          id="leadassignbtn"
        >
          Assign
        </Link>
            </Fragment>:<Fragment>
            <Link
          className="disabledCursor"
          id="leadassignbtn"
        >
          Assign
        </Link>
            </Fragment>
          }
          
        </Fragment>
      ) : dataAll.gdStatusVO?.gdStatus !== "Pending" &&
      dataAll.userVO?.userName !== null ? (
        <Fragment>
          {
            privilegedata[1]?.privilegeId === 103 && privilegedata[1]?.privilageFlag === true?<Fragment>
 <Link
            alt=""
            id="leadassignbtn"
            className="notDisabled"
            onClick={() =>
              NavigateTOAssignModal(dataAll.leadID)
            }
          >
            {dataAll.userVO?.userName}
          </Link>
            </Fragment>:<Fragment>
            <Link
            alt=""
            id="leadassignbtn"
            className="notDisabled"
          >
            {dataAll.userVO?.userName}
          </Link>
            </Fragment>
          }
         
        </Fragment>
      ) : (
        <Fragment>
          <Link
            onClick={completeJD}
            className="disabledCursor"
            id="disabledCursor"
            
          >
            Assign
          </Link>
        </Fragment>
  ))
}

  const [isSubmitted, setSubmitted] = useState(false);
  const handleChangeForLeadSource = (e) => {
    SetViewLead({
      ...viewLead,
      userVO: {
        userId: e.target.value,
      },
    });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    console.log("leadData", viewLead);
    axios
      .put("https://pradhanportal.com/loanApplication/master/editLeadOwner", viewLead)
      .then((response) => {
        console.log(response);

        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  
  const exportToExcel = () => {
    const filteredLeadData = filterLeads.map((data) => ({
      "Id": data.id,
      "Date": data.createdDate,
      "Customer Name": data.customerName,
      "Mobile Number": data.primaryNumber,
      "Lead Type": data.typesVO?.leadTypeVO?.leadType,
      "Sub Type": data.typesVO?.type,
      "GD Status": data.gdStatusVO?.gdStatus,
      "Lead Owner": data.userVO?.userName,
    }));
    
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredLeadData);
    XLSX.utils.book_append_sheet(wb, ws, "Leads");
  
    const todayDate = moment().format("YYYY-MM-DD");
    const filename = `Leads_${todayDate}.xlsx`;
  
    // Ensure the correct MIME type and download
    XLSX.writeFile(wb, filename, { bookType: "xlsx", type: "binary" });
  };
  
  
  const createdDate=(dataAll)=>{
    return moment(dataAll.createdDate)
    .format("DD-MMM-YYYY")
  }


  const authUser = JSON.parse(localStorage.getItem("user"));
    const obj=authUser?.role?.role;
    const roleId=authUser?.role?.roleId;

    const [privilegedata, setPrivilegeData] = useState([]);

    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getAllRolePrivilegeFlagsByRoleId?roleId=${roleId}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("setPrivilegeData response :", response.data);
          setPrivilegeData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

  return (
    <Fragment>
      <div className="container-fluid">
        <Row>
          <Col lg={12}>
            <Card id="leadsList">
              {/* <CardHeader className="border-0"> */}
                <Row className="g-4 align-items-center">
                  <Col sm={3} style={{marginTop:"40px",paddingLeft:"30px"}}>
                    <div className="d-flex align-items-center ">
                    {
                      privilegedata[0]?.privilegeId === 101 && privilegedata[0]?.privilageFlag === true?<Fragment>
                       <div className="d-inline-block">
                        <h3> Leads</h3>
                      </div>
                      <div className="d-inline-block mx-1">
                        <Tooltip placement="right" overlay={renderTooltip}>
                          <h2>
                            <i
                              onClick={navigateToCreateLead}
                              className="bi bi-plus-square-fill btn btn-lg"
                            ></i>{" "}
                          </h2>
                        </Tooltip>
                      </div>
                      </Fragment>:null
                    }
                      
                    </div>
                  </Col>
                  <Col sm={5}></Col>
                  <Col sm={8} style={{marginTop:"0px", paddingLeft:"30px"}}>
                  <div className='' style={{float:'left'}}>
           <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
           value={showRecord}
                     className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                     <option  value="10">Show 10</option>
                     <option value="25">Show 25</option>
                     <option value="50">Show 50</option>
                     <option value="100">Show 100</option>
   
                     </select>
                     </div>
                  </Col>
                  {/* <Col sm={1}></Col> */}
                  <Col sm={1} style={{marginTop:"0px"}}>
                  <div className="d-inline-block">
                        <Tooltip placement="left" overlay={renderTooltipexcel}>
                          {/* <h2>
                            <i
                              onClick={navigateToCreateLead}
                              className="bi bi-file-excel btn btn-lg"
                            ></i>{" "}

                          </h2> */}
                           <img src={excelIcon} width = "35" height = "30" alt='' className='mx-1'  onClick={exportToExcel} style={{cursor:"pointer"}}/>
                        </Tooltip>
                      </div>
                  </Col>
                  <Col sm={3} style={{marginTop:"0px",paddingRight:"30px"}}>
                
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder="Search for..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                </Row>
              {/* </CardHeader> */}
              <div className="row">
              <div className="col-xxl-12">
              <CardBody className="pt-0 mt-4 lg-12">
                {
                  dataAll?.length > 0 ? <>
                 <div className="container-fluid">
                 <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX:"auto",height:"600px"}}
                    id="tableData">
                      <table className="table align-items-center table-flush table-hover" id="dataTableHover">
                        <thead className="tableheader">
                        <tr>    
          <th scope="col" className="tableroww">Sr.No.</th>
       <th scope="col" className="tableroww">Lead ID</th>
         <th scope="col" className="tableroww">Date</th>
         <th scope="col" className="tableroww">Customer Name</th>
         <th scope="col" className="tableroww">Mobile Number</th>
         <th scope="col" className="tableroww">Lead Type</th>
         <th scope="col" className="tableroww">Sub Type</th>
         <th scope="col" className="tableroww">GD Status</th>
         <th scope="col" className="tableroww">Lead Ageing</th>
         <th scope="col" className="tableroww">Lead Owner</th>
       </tr>
     </thead>
     <tbody>
      
       {
           dataAll?.map((dataAll,index)=>( 
               <tr key={index}>
               <th scope='row' className="tableroww1">{index + 1}</th>
               
               {
                privilegedata[1]?.privilegeId === 102 && privilegedata[1]?.privilageFlag === true?<Fragment>
<td className="tableroww1 capitalizetxxxt">
               <Link id="leadid1" to={`/ViewLead/${dataAll.leadID}`} >
         {dataAll.id}
            </Link>
               </td>
                </Fragment>:<Fragment>
                <td className="tableroww1 capitalizetxxxt">
               <Link id="leadid1" >
         {dataAll.id}
            </Link>
               </td>
                </Fragment>
               }
               <td className="tableroww1 capitalizetxxxt">{createdDate(dataAll)}</td>
                <td className="tableroww1 capitalizetxxxt">{dataAll.customerName}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll.primaryNumber !== "" ? dataAll.primaryNumber:dataAll.contactPerson1stNumber}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll.typesVO.leadTypeVO.leadType}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll.typesVO.type}</td>
               <td className="tableroww1 capitalizetxxxt">{gdStatus(dataAll)}</td>
               <td className="tableroww1 capitalizetxxxt">{leadAgeing(dataAll)}</td>
               
               <td className="tableroww1 capitalizetxxxt">{leadOwner(dataAll)}</td>
               </tr>
   
   
            ))
       } 
     </tbody>
       </table>
       {dataAll?.length > 0 && (
                      <div className="" style={{ float: "right" }}>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => handlePrevios()}
                            disabled={page === 1}
                          />
                          {Array(pageCount)
                            ?.fill(null)
                            ?.map((ele, index) => {
                              return (
                                <>
                                  <Pagination.Item
                                    active={page === index + 1 ? true : false}
                                    onClick={() => setPage(index + 1)}
                                  >
                                    {index + 1}
                                  </Pagination.Item>
                                </>
                              );
                            })}
                          <Pagination.Next
                            onClick={() => handleNext()}
                            disabled={page === pageCount}
                          />
                        </Pagination>
                      </div>
                    )}
                            </div>
                  </div>
                 </div>
                 </div>
                  </>:<>No Records Available</>
                }
              </CardBody>
              </div>
              </div>
              
              {isSubmitted ? (
                navigate("/LeadCreation-Lead")
              ) : (
                <Modal isOpen={modal} id="assignmodal">
                  <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                    <span id="assignowner1">Assign Lead</span>
                  </ModalHeader>
                  <ModalBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={SubmitForm}>
                            <div className="row">
                              <div className="col-md-4 mt-3">
                                <span className="d-flex justify-content-end">
                                  Customer Name
                                </span>
                              </div>
                              <div className="col-md-8 mt-3">
                                {viewLead.customerName}
                              </div>
                              <div className="col-md-4 mt-3">
                                <span className="d-flex justify-content-end">
                                  Lead Type
                                </span>
                              </div>
                              <div className="col-md-8 mt-3">
                                {viewLead.leadTypeVO?.leadType}
                              </div>
                              <div className="col-md-4 mt-3">
                                <span className="d-flex justify-content-end  ">
                                  Executive Name
                                </span>
                              </div>
                              <div className="col-md-8 mt-2">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-md example"
                                  name="userVO.userId"
                                  onChange={handleChangeForLeadSource}
                                  value={viewLead.userVO?.userId}
                                >
                                  <option selected>Please Select</option>
                                  {user?.length > 0 ? (
                                    <Fragment>
                                      {user.map((role, index) => {
                                        return (
                                          <option
                                            value={role.userId}
                                            key={index}
                                          >
                                            {role.userName}
                                          </option>
                                        );
                                      })}
                                    </Fragment>
                                  ) : null}
                                </select>
                              </div>
                              <div className="col-md-12 mt-4">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                  <Button
                                    color="secondary"
                                    id="btn-cancel"
                                    onClick={toggle}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    id="btn-cancel"
                                  >
                                    Assign Lead
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
