import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
import "../../../assets/scss/pages/lead.css";
import axios from "axios";
import moment from 'moment';
import excelIcon from "../../../assets/images/excel.png";
import Tooltip from "rc-tooltip";
import { Pagination } from 'react-bootstrap';
import * as XLSX from "xlsx";
export default function LoanCase({LeadData,leadData}) {
  var renderTooltip = <span>Add New Stage</span>;
    const [data, setData] = useState([]);
const[dataAll,setDataAll]=useState([]);
var renderTooltipexcel = <span>Export Data</span>;
var renderTooltip = <span>Create Lead</span>;
var renderTooltipexcel = <span>Export Data</span>;
  //for pagination
 
  const [pageData, setPageData] = useState([]);
 const [page, setPage] = useState(1);
 const [pageCount, setPageCount] = useState(10);
 const [search, setSearch] = useState("");


    useEffect(() => {
      loadConnects();
     },[page, LeadData, leadData]);
  
     const loadConnects = async (value) => {
      const pageSize = value ? value : showRecord;
      if (value != null && value == 10) 
      {
        
        const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllCases?pageNumber=`+page+`&pageSize=`+pageSize)
        setDataAll(result.data);
       // setPageData(result.data);
        console.log("getListOfAllCases",result.data)

        console.log("getListOfAllCasesValue in if",value);
      } 
      else 
      {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllCases?pageNumber=`+page+`&pageSize=`+pageSize)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      }
 };
 const[cp,setSP]=useState([])
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllLoanCases`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setSP(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const exportToExcel = () => {
    const filteredLeadData = dataAll.map((data) => ({
      "Id": data.id,
      "Date": data.dob,
      "Customer Name": data.customerName,
      "Mobile Number": data.primaryNumber,
      "Lead Type": data.typesVO?.leadTypeVO?.type,
      "Sub Type": data.subTypesVO?.subTypes,
      "Customer Type":data.customerType,
      "Loan Amount":data.amount,
      "email": data.emailID,
      "Stage":data.templateTask?.loanStage?.loanStage,
      "Task":data.templateTask?.loanTaskVO?.loanTask,
      "Owner":data.ownerVO?.ownerName,
      "Created Date": data.createdDate,
    }));
    
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredLeadData);
    XLSX.utils.book_append_sheet(wb, ws, "Loan_Cases");
  
    const todayDate = moment().format("YYYY-MM-DD");
    const filename = `Loan_Cases_${todayDate}.xlsx`;
  
    // Ensure the correct MIME type and download
    XLSX.writeFile(wb, filename, { bookType: "xlsx", type: "binary" });
  };

 const [showRecord, setshowRecord] = useState(10);

  const handleChanegeShowRec = (e) => {
    const { name, value, type, checked } = e.currentTarget;

    setshowRecord(e.currentTarget.value);

    loadConnects(value);
  };

  
 // handle next
 const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1)
 }
 
 // handle previous
 const handlePrevios = () => {
    if (page === 1) return page;
    setPage(page - 1)
 }
 
 useEffect(() => {
  loadConnects()
 }, [page])
 
 useEffect(() => {
    const pagedatacount = Math.ceil(cp?.length / showRecord);
    setPageCount(pagedatacount);
 console.log("pagedatacount",pagedatacount);
    if (page) {
        const LIMIT = showRecord;
        console.log("LIMIT",LIMIT);
        const skip = LIMIT * page // 5 *2 = 10
        console.log("skip",skip);
         const dataskip = dataAll?.slice(page === 1 ? 0 : skip - LIMIT, skip);
         console.log("SKIP DATA",dataskip);
        setPageData(dataskip)
    }
 }, [dataAll])
 

 useEffect(()=>{
  const result= dataAll.filter((item)=>{
   return item.customerName.toLowerCase().match(search.toLocaleLowerCase());
  });
  setPageData(result);
},[search]);

  const createdDate=()=>{
    return moment(dataAll.createdDate)
    .format("DD-MMM-YYYY")
  }
  const authUser = JSON.parse(localStorage.getItem("user"));
  const obj=authUser?.role?.role;
  const roleId=authUser?.role?.roleId;

  const [privilegedata, setPrivilegeData] = useState([]);

  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllRolePrivilegeFlagsByRoleId?roleId=${roleId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setPrivilegeData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <fragment>
      <Container fluid>
            <Row>
                <Col lg={12}>
                
                <Card id="leadsList">
                <CardHeader className="border-0">
                <Row className="g-4 align-items-center mt-3">
                 
                  {/* <Col sm={5}></Col> */}
                  <Col sm={8} style={{marginTop:"0px", paddingLeft:"30px"}}>
                  <div className='' style={{float:'left'}}>
           <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
           value={showRecord}
                     className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                     <option  value="10">Show 10</option>
                     <option value="25">Show 25</option>
                     <option value="50">Show 50</option>
                     <option value="100">Show 100</option>
   
                     </select>
                     </div>
                  </Col>
                  {/* <Col sm={1}></Col> */}
                  <Col sm={1} style={{marginTop:"0px"}}>
                  <div className="d-inline-block">
                        <Tooltip placement="left" overlay={renderTooltipexcel}>
                         
                           <img src={excelIcon} width = "35" height = "30" alt='' className='mx-1'  onClick={exportToExcel} style={{cursor:"pointer"}}/>
                        </Tooltip>
                      </div>
                  </Col>
                  <Col sm={3} style={{marginTop:"0px",paddingRight:"30px"}}>
                
                <div className="search-box">
                  <Input
                    type="text"
                    className="form-control search"
                    placeholder="Search for..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </Col>
                </Row>
              </CardHeader>
                <CardBody className="pt-0">
               {
                dataAll?.length > 0 ? <>
                 <div className="container-fluid">
                 <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX:"auto",height:"600px"}}
                    id="tableData">
                      <table className="table align-items-center table-flush table-hover" id="dataTableHover">
                        <thead className="tableheader">
                        <tr>    
            <th scope="col" className="tableroww">Sr.No.</th>
            <th scope="col" className="tableroww">Case ID</th>
         <th scope="col" className="tableroww">Date</th>
         <th scope="col" className="tableroww">Customer Name</th>
         <th scope="col" className="tableroww">Mobile Number</th>
         <th scope="col" className="tableroww">Loan Type</th>
         <th scope="col" className="tableroww">Task</th>
         <th scope="col" className="tableroww">Task Owner</th>
       </tr>
     </thead>
     <tbody>
      
       {
           dataAll?.map((dataAll,index)=>( 
               <tr key={index}>
               <th scope='row' className="tableroww1">{index + 1}</th>
              
               {
                privilegedata[9]?.privilegeId === 110 && privilegedata[9]?.privilageFlag === true?<Fragment>
  <td className="tableroww1 capitalizetxxxt">
               <Link id="leadid1" to={`/viewLoanCase/${dataAll?.loanCaseId}`}>
         {dataAll?.createdId}
            </Link>
               </td>
                </Fragment>:<Fragment>
                <td className="tableroww1 capitalizetxxxt">
               <Link id="leadid1">
         {dataAll?.createdId}
            </Link>
               </td>
                </Fragment>
               }
               <td className="tableroww1 capitalizetxxxt">{dataAll?.createdDate}</td>
                <td className="tableroww1 capitalizetxxxt">{dataAll?.customerName}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll?.primaryNumber}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll?.typesVO?.type}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll?.templateTask != null ? dataAll?.templateTask?.loanTaskVO?.loanTask:dataAll?.loanCaseTask?.taskName
               }</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll?.templateTask != null ? dataAll?.templateTask.userVO?.userName:dataAll?.loanCaseTask?.userVO?.userName}</td>
               </tr>
   
   
            ))
       } 
       </tbody>
       </table>
       {dataAll?.length > 0 && (
                      <div className="" style={{ float: "right" }}>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => handlePrevios()}
                            disabled={page === 1}
                          />
                          {Array(pageCount)
                            .fill(null)
                            .map((ele, index) => {
                              return (
                                <>
                                  <Pagination.Item
                                    active={page === index + 1 ? true : false}
                                    onClick={() => setPage(index + 1)}
                                  >
                                    {index + 1}
                                  </Pagination.Item>
                                </>
                              );
                            })}
                          <Pagination.Next
                            onClick={() => handleNext()}
                            disabled={page === pageCount}
                          />
                        </Pagination>
                      </div>
                    )}
                            </div>
                  </div>
                 </div>
                 </div>
                </>:<>
                <div>No Records Available</div></>
               }
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </fragment>
  )
}

