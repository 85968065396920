import React,{Fragment, useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Tooltip from "rc-tooltip";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
export default function EditUser() {
  var renderTooltip = <span>Delete User</span>;
    const {userId}=useParams();
    const navigate = useNavigate();
    const NavigateToViewUser =()=>{
      navigate(`/viewUser/${userId}`);
    }
    const[data,setData]=useState({
      userId:'',
      userName:'',
      email:'',
      gender:'',
      dob:'',
      city:{
        cityID:''
      },
      branch:{
        branchID:''
      },
      primaryNumber:'',
      secondaryNumber:'',
      role:{
        roleId:''
      },
      status:'',  
    })

    useEffect (()=>{
        const dataUrl =`https://pradhanportal.com/loanApplication/master/getUserById?userId=${userId}`;
            axios.get(dataUrl).then((response)=>{
              setData(response.data);
              console.log("response :",response.data);
            }).catch((err)=>{
               console.log(err);
            });
        
      },[userId]);

console.log("DOB....",data.dob);

      const cityId = data?.city?.cityID;
      console.log("cityId",cityId);
      const handleChange = (e) => {  
        setData({ 
            ...data,
          [e.target.name]: e.target.value       
        }); 
      };

      const[city,setCity]=useState([]);
      useEffect(()=>{
        const d1 =`https://pradhanportal.com/loanApplication/master/getAllCities`;
        axios.get(d1).then((response)=>{
          console.log("response :",response.data)
      
          setCity(response.data);
        }).catch((err)=>{
           console.log(err);
        });
      },[]);
    
     
    
    const[branch,setBranch]=useState([]);
    useEffect(()=>{
      const d1 =`https://pradhanportal.com/loanApplication/master/getAllBranchesByCityId?cityID=${cityId}`;
      axios.get(d1).then((response)=>{
        console.log("response :",response.data)
        setBranch(response.data);
      }).catch((err)=>{
         console.log(err);
      });
    },[cityId]);
    
    
    const[role,setRole]=useState([]);
    useEffect(()=>{
      const d1 =`https://pradhanportal.com/loanApplication/master/getAllRole`;
      axios.get(d1).then((response)=>{
        console.log("response :",response.data)
        setRole(response.data);
      }).catch((err)=>{
         console.log(err);
      });
    },[]);


    const handleChangeCity=(e)=>{
      setData({ 
        ...data,
        city:{
          cityID: e.target.value }
    }); 
    }


    const handleChangeRole=(e)=>{
      setData({ 
        ...data,
        role:{
          roleId: e.target.value }
    }); 
    }


    const handleChangeBranch=(e)=>{
      setData({ 
        ...data,
        branch:{
          branchID: e.target.value }
    }); 
    }

    const [isSubmitted, setSubmitted]=useState(false);

    const SubmitForm=(e)=>{
    
      e.preventDefault();
      // console.log("leadData",leadData);
      axios.put('https://pradhanportal.com/loanApplication/master/editUser',data).then((response)=>{
          console.log(response);
          
          setSubmitted(true);
        }).catch((err)=>{
          console.error(err);
        });
      // setSubmitted(true);
     
    
    }; 

    const deleteUser = () => {
      const dataUrl =`https://pradhanportal.com/loanApplication/master/deleteUser?userId=${userId}`;
      axios.post(dataUrl).then((response)=>{
        console.log("response :",response.data);
        navigate(`/userMgmtPool`);
      toast.success("User deleted successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      }).catch((err)=>{
         console.log(err);
      });
    };
  return (
    <Fragment>
      {
       isSubmitted ? navigate(`/viewUser/${userId}`) &
       toast.success('User Updated successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                    <Tooltip placement="right" overlay={renderTooltip}>
                  <h3 id="trashbtn">
                    <i
                      class="bi bi-trash  btn-lg"
                      onClick={deleteUser}
                    ></i>
                  </h3>
                </Tooltip>
                        <form onSubmit={SubmitForm}>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                </div>
                            </div>
                            <div className="row mt-4">
                            <div className="col-md-2 mt-1">
                        <span className="d-flex justify-content-end">User Name</span>
                      </div>
                      <div className="col-md-5">
                        <input
                          type="text"
                          name="userName"
                          className="form-control sm"
                          placeholder="Enter User Name"
                          onChange={handleChange}
                          value={data.userName}
                        />
                      </div>
                      <div className="col-md-5"></div>
                      <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end">Gender</span>
                        </div>
                        <div className="col-md-5 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio7"
                              onChange={handleChange}
                              checked={data.gender === "Male"}
                              value="Male"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio8"
                              onChange={handleChange}
                              checked={data.gender === "Female"}
                              value="Female"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio9"
                              onChange={handleChange}
                              checked={data.gender === "Other"}
                              value="Other"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="col-md-5"></div>
                        <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end"> DOB</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="date"
                          name="dob"
                          className="form-control sm"
                          placeholder="dd-mm--yyyy"
                          onChange={handleChange}
                          // value={data.dob}
                          value={data?.dob !=="" ? moment(data?.dob).format('YYYY-MM-DD') : ""}
                        />
                      </div><div className='col-md-5'></div>
                      <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end">Email ID</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="email"
                          name="spouseDOB"
                          className="form-control sm"
                          placeholder="Enter Email ID"
                          onChange={handleChange}
                          value={data.email}
                        />
                      </div><div className='col-md-5'></div>
                      <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end">Primary Number</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="number"
                          name="primaryNumber"
                          className="form-control sm"
                          placeholder="Enter Primary Mobile Number"
                          onChange={handleChange}
                          value={data.primaryNumber}
                        />
                      </div><div className='col-md-5'></div>
                      <div className="col-md-2 mt-3">
                        <span className="d-flex justify-content-end">Secondary Number</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="number"
                          name="secondaryNumber"
                          className="form-control sm"
                          placeholder="Enter Secondary Mobile Number"
                          onChange={handleChange}
                          value={data.secondaryNumber}
                        />
                      </div><div className='col-md-5'></div>
                      <div className="col-md-2 mt-3">
                      <span className="d-flex justify-content-end  ">City</span>
                          </div>
                          <div className="col-md-5 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='city.cityID'
                          onChange={handleChangeCity}
                          value={data.city?.cityID}
                        >
                          <option value="">Please Select</option>
                          {
                     city?.length > 0 ? <Fragment>
                       {
                         city.map((cities,index)=>{
                           return(
                             <option value={cities?.cityID}key={index}>{cities.cityName}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                        </select>
                          </div><div className='col-md-5'></div>
                          <div className="col-md-2 mt-3">
                      <span className="d-flex justify-content-end  ">Branch</span>
                          </div>
                          <div className="col-md-5 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='branch.branchID'
                          onChange={handleChangeBranch}
                          value={data.branch?.branchID}
                        >
                          <option value="">Please Select</option>
                          {
                     branch?.length > 0 ? <Fragment>
                       {
                         branch.map((branches,index)=>{
                           return(
                             <option value={branches?.branchID}key={index}>{branches.branch}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                        </select>
                          </div><div className='col-md-5'></div>
                          <div className="col-md-2 mt-3">
                      <span className="d-flex justify-content-end  ">User Role</span>
                          </div>
                          <div className="col-md-5 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='role.roleId'
                          onChange={handleChangeRole}
                          value={data.role?.roleId}
                        >
                          <option value="">Please Select</option>
                          {
                     role?.length > 0 ? <Fragment>
                       {
                         role.map((roles,index)=>{
                           return(
                             <option value={roles?.roleId}key={index}>{roles.role}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                        </select>
                          </div><div className='col-md-5'></div>
                          <div className="col-md-2 mt-3">
                      <span className="d-flex justify-content-end  ">User Status</span>
                          </div>
                          <div className="col-md-5 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='status'
                          onChange={handleChange}
                          value={data.status}
                        >
                          <option value="">Please Select</option>
                          <option value="Active">Active</option>
                          <option value="Disable">Disable</option>
                        </select>
                          </div><div className='col-md-5'></div>
                            </div>
                            <div className="row">
                              <div className="col-md-7">
                              <div class="d-grid gap-2 d-md-flex justify-content-md-center my-4">
                          <button
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                            onClick={NavigateToViewUser}
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" id="Cancelbtn2">
                            Update
                          </button>
                        </div>
                              </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
}
    </Fragment>
  )
}

