import React, { useState } from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useEffect } from 'react';
import validator from 'validator';
import PropTypes from "prop-types";
import { Link} from "react-router-dom";
import { useParams } from 'react-router-dom';
import Tooltip from "rc-tooltip";
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap"
import { toast } from 'react-toastify';
export default function UpdateLeads() {
  const [isSubmitted, setSubmitted]=useState(false);
  const navigate = useNavigate();
  const [inputText, setInputText] = useState('');
  const [characterLimit] = useState(300);
  const[visibleContPerson,setVisibleContPerson]=useState(false);
  const {LeadID}=useParams();
  
  const navigateToCreateLead = () => {
    navigate(`/ViewLead/${LeadID}`);
  }
    // Modal open state
   const [modal, setModal] = React.useState(false);
  
   // Toggle for Modal
   const toggle = () => setModal(!modal);
  

  const[
    leadData,setLeadData]=useState({     
      customerName: "",
      customerId: "",
      lookingFor:"",
      customerType:'',
      residentTypeVO: {
        residentTypeID: "",
      },
      customerTypeVO: {
        customerTypeID: "",
      },
      leadTypeVO: {
        leadTypeID: "",
      },
      typesVO: {
        typeId: "",
      },
      subTypesVO: {
        subTypeID: "",
      },
      initiatorVO: {
        initiatorID: "",
      },
      branchVO: {
        branchID: "",
      },
      bankVO:{
        bankId:'',
      },
      bankBranchVO:{
        bankBranchId:'',
      },
      ownerVO: {
        ownerID: "",
      },
      userVO:{
        userId:'',
      },
      partnerVO:{
        partnerId:'',
      },
      partnershipVO:{
        partnershipId:'',
      },
      projectName:'',
      gdStatusVO: {
        gdStatusID: "",
      },
      leadSourceVO: {
        leadSourceID: "",
      },
      reqCountry:'',
      countryId:'',
      reqCountryId:'',
      state:'',
      stateId:'',
      stateCode:'',
      addressLine1:"",
      addressLine2:"",
      landMark:'',
      coverAmount:'',
      pinCode:'',
      previousPolicy:"",
      addOn:"",
      gender: "",
      dob:'',
      paymentMode:"",
      rcNumber:"",
      previousPolicyCover:"",
      previousPolicyCompany:"",
      previousDiseaseOfCustomer1:"",
      propertyAge:"",
      propertyCost:"",
      previousLoanAmount:"",
      addOnEstimatedCost:"",
      nomineeName:"",
      nomineedob:"",
      nomineeRelation:"",
      familyMemberCount:"",
      familyMemberName:"",
      familyMembergender:"",
    directorName:"",
      primaryNumber: "",
      secondaryNumber: "",
      emailID: "",
      amount: "",
      gdLink: "",
      directorPrimaryNumber: "",
      directorSecondaryNumber: "",
      directorEmailId: "",
      customerContactDetails: "",
      contactPerson1stNumber: "",
      contactPerson2ndNumber: "",
      contactPersonEmail: "",
      contactPerson: "",
      contactPersonRelation: "",
      leadNotes: "",
      text: "",
      item: "",
  })

  
  


  useEffect (()=>{
    const dataUrl =`https://pradhanportal.com/loanApplication/master/getLeadById?leadID=${LeadID}`;
        axios.get(dataUrl).then((response)=>{
          setLeadData(response.data);
          console.log("response :",response.data);
        }).catch((err)=>{
           console.log(err);
        });
    
  },[LeadID]);
console.log("LeadData=====",leadData)
  // pradhanportal.com/loanApplication
  const[residentType,setResidentType]=useState();
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllResidentType`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setResidentType(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const[leadSource,setLeadSurce]=useState([]);
  useEffect(()=>{
    const d3 =`https://pradhanportal.com/loanApplication/master/getAllLeadSource`;
    axios.get(d3,).then((response)=>{
      console.log("response :",response.data)
  
      setLeadSurce(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const[leadTypes,setLeadTypes]=useState([]);
  useEffect(()=>{
    const d5 =`https://pradhanportal.com/loanApplication/master/getAllLeadType`;
    axios.get(d5).then((response)=>{
      console.log("response :",response.data)
  
      setLeadTypes(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);


  const typeID=leadData?.typesVO?.typeId;
  const[subtypes,setSubTypes]=useState({});
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getSubTypesByTypeID?typeID=${typeID}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data.data)
  
      setSubTypes(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[typeID]);


  const iniID= leadData?.initiatorVO?.initiatorID;
  console.log("iniid....",iniID);
  const[iniBranch,setIniBranch]=useState({});
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getInitiatorById?initiatorID=${iniID}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
  
      setIniBranch(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[iniID]);
  console.log(iniBranch);

  const[leadOwners,setLeadOwners]=useState([]);
  useEffect(()=>{
    const d1 =`https://pradhanportal.com/loanApplication/master/getAllOwner`;
    axios.get(d1).then((response)=>{
      console.log("response :",response.data)
  
      setLeadOwners(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const[custTypes,setCustTypes]=useState([]);
  useEffect(()=>{
    const d4 =`https://pradhanportal.com/loanApplication/master/getAllCustomerType`;
    axios.get(d4).then((response)=>{
      console.log("response :",response.data)
  
      setCustTypes(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);
  console.log("custTypes",custTypes);
  

  const[leadInitiator,setLeadInitiator]=useState([]);
  useEffect(()=>{
    const d2 =`https://pradhanportal.com/loanApplication/master/getAllInitiator`;
    axios.get(d2,).then((response)=>{
      console.log("response :",response.data)
  
      setLeadInitiator(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[]);

  const [user, setUser] = useState({});
  useEffect(() => {
    const d6 = 'https://pradhanportal.com/loanApplication/master/getAllUsers';
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);

        setUser(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [partner,setPartner] = useState({});
  useEffect(() => {
    const d6 = 'https://pradhanportal.com/loanApplication/master/getAllPartner';
    axios
      .get(d6)
      .then((response) => {
        console.log("Partner List response :", response.data);
        setPartner(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeForPartner =(e)=>{
    setLeadData({
        ...leadData,
        partnerVO: {
          partnerId:e.target.value
          },
    })
  }
const partnerId=leadData.partnerVO?.partnerId;
  const [partnerShip,setPartnerShip] = useState({});
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllCPBypartnerId?partnerId=${partnerId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("Partner List response :", response.data);
        setPartnerShip(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [partnerId]);

  const handleChangeForPartnerShip =(e)=>{
    setLeadData({
        ...leadData,
        partnershipVO: {
          partnershipId:e.target.value
          },
    })
  }
  const [banks,setBanks] = useState({});
  useEffect(() => {
    const d6 = 'https://pradhanportal.com/loanApplication/master/getAllBank';
    axios
      .get(d6)
      .then((response) => {
        console.log("Bank List response :", response.data);

        setBanks(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const bnkId= leadData.bankVO.bankId;
  console.log("BankID",bnkId);
  const [bankBranch, setBankBranch] = useState({});
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllBranchByBankId?bankId=${bnkId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);

        setBankBranch(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bnkId]);
  console.log("bankBranch",bankBranch);

  const handleChangeForBank =(e)=>{
    setLeadData({
        ...leadData,
        bankVO: {
          bankId:e.target.value
          },
    })
  }
  const handleChangeForBankBranch =(e)=>{
    setLeadData({
        ...leadData,
        bankBranchVO: {
          bankBranchId:e.target.value
          },
    })
  }


  const ownerID =leadData?.userVO?.userId;
  console.log("ownerID",ownerID);
  const[getOwner,setGetOwner]=useState('');
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getUserById?userId=${ownerID}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data)
  
      setGetOwner(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[ownerID]);

  const[status,setStatus]=useState('');
    useEffect(()=>{
      const d6 =`https://pradhanportal.com/loanApplication/master/getAllGdStatus`;
      axios.get(d6).then((response)=>{
        console.log("response :",response.data)
    
        setStatus(response.data);
      }).catch((err)=>{
         console.log(err);
      });
    },[]);

    // const validateForm = () => {
    //   let isValid = true;
    //   const newErrors = {};
  
    //   // validate Customer Name
    //   if(!leadData.customerName){
    //     newErrors.customerName = "Please Enter Customer Name";
    //     isValid = false;
    //     if(leadData.customerName.length > 0){
    //       isValid = true;
    //     }
    //   }
    //   // Validate email
    //   if (!leadData.emailID) {
    //     newErrors.emailID = "Email is required";
    //     isValid = false;
    //   }
  
    //   setErrors(newErrors);
    //   return isValid;
    // };

    const SubmitOwnerForm=()=>{

    }
    const [error, setError] = useState({});
      const SubmitForm=(e)=>{
      
        e.preventDefault();
      if (validator.isEmpty(leadData.customerName))
      {
        setError(true);
      } 
      // else if(validator.isEmpty(leadData.gender))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.leadSourceVO.leadSourceID))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.customerTypeVO.customerTypeID))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.contactPerson))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.primaryNumber))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.leadTypeVO.leadTypeID))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.typesVO.typeId))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.amount))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.userVO.userId))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.gdStatusVO.gdStatusID))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.customerContactDetails))
      // {
      //   setError(true);
      // }
      // else if(validator.isEmpty(leadData.contactPerson1stNumber))
      // {
      //   setError(true);
      // }
      else{
          axios.put('https://pradhanportal.com/loanApplication/master/editLead',leadData).then((response)=>{
            console.log(response);
            
            setSubmitted(true);
          }).catch((err)=>{
            console.error(err);
          });
}
      };


    const handleChange = (e) => {  
      setLeadData({ 
          ...leadData,
        [e.target.name]: e.target.value       
      }); 
    };

    const handleChangeForResidentType=(e)=>{
      console.log("Name :",e.target.name)
      console.log("Value :",e.target.value);
      setLeadData({ 
        ...leadData,
        residentTypeVO:{
          residentTypeID: e.target.value } 
    
    
    }); 
    }

    const handleChangeForLeadSource=(e)=>{
      console.log("Name :",e.target.name)
      console.log("Value :",e.target.value);
      setLeadData({ 
        ...leadData,
        leadSourceVO:{
          leadSourceID: e.target.value } 
    }); 
    }

    const handleChangeCustomerType=(e)=>{
      setLeadData({ 
        ...leadData,
        customerTypeVO:{
          customerTypeID: e.target.value } 
    
    
    }); 
    }

    const handleChangeForLeadType=(e)=>{
      setLeadData({ 
        ...leadData,
        leadTypeVO:{
          leadTypeID: e.target.value } 
    }); 
    }

    const handleChangeForType=(e)=>{
      setLeadData({ 
        ...leadData,
        typesVO:{
          typeId: e.target.value } 
    }); 
    }
    const handleChangeForSubType=(e)=>{
      setLeadData({ 
        ...leadData,
        subTypesVO:{
          subTypeID: e.target.value } 
    }); 
    }

    const handleChangeForInitiator=(e)=>{
      setLeadData({ 
        ...leadData,
        initiatorVO:{
          initiatorID: e.target.value } 
    }); 
    }

    const handleChangeForGdDtatus=(e)=>{
      setLeadData({ 
        ...leadData,
        gdStatusVO:{
          gdStatusID: e.target.value } 
    }); 
    }

    const handleChangeForOwner=(e)=>{
      setLeadData({ 
        ...leadData,
        userVO:{
          userId: e.target.value } 
    }); 
    }

    console.log("leadDAta...",leadData);
    var renderTooltip = <span>Delete Lead</span>;
    const [modal1, setModal1] = React.useState(false);
    const toggle1 = () => setModal1(!modal1);
    const DeleteLead = () => {
      const dataUrl =`https://pradhanportal.com/loanApplication/master/updateFlag?leadID=${LeadID}`;
          axios.put(dataUrl).then((response)=>{
          
            console.log("response :",response.data);
            
            setLeadData(response.data);
          }).catch((err)=>{
             console.log(err);
          });
    navigate(`/LeadCreation-Lead`);
    toast.success('Lead Deleted successfully !', {
      position: toast.POSITION.TOP_RIGHT
  });
  };

  const char=leadData?.leadNotes?.length;
  console.log("char count function calling...",300-char);

  const NavigateToViewLead =()=>{
    toast.success('Lead Deleted successfully !', {
      position: toast.POSITION.TOP_RIGHT
  });
  }

  const ltID=leadData.leadTypeVO?.leadTypeID;
  console.log("leadtypeI========",ltID);
  const [leadTyp, setLeadType]=useState({});
  useEffect(()=>{
    const d6 =`https://pradhanportal.com/loanApplication/master/getTypesByLeadID?leadTypeID=${ltID}`;
    axios.get(d6).then((response)=>{
      console.log("response :",response.data);
  
      setLeadType(response.data);
    }).catch((err)=>{
       console.log(err);
    });
  },[ltID]);


  const NavigateTOAssignModal = () => {
    toggle();
  };

  const authUser = JSON.parse(localStorage.getItem("user"));
  const obj=authUser?.role?.role;
  const roleId=authUser?.role?.roleId;

  const [privilegedata, setPrivilegeData] = useState([]);

  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllRolePrivilegeFlagsByRoleId?roleId=${roleId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setPrivilegeData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
      <Fragment>
     
     {
       isSubmitted ? navigate(`/ViewLead/${LeadID}`) &
       toast.success('Lead Updated successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
     
     <div className="container-fluid leads"> 
       <div className="row">
         <div className="col-md-12">
           <div className="card">
           {
            privilegedata[3]?.privilegeId === 104 && privilegedata[3]?.privilageFlag === true ? <Fragment>
            <Tooltip  placement="right" overlay={renderTooltip} >
                <h3 id="trashbtn">
                <i class="bi bi-trash  btn-lg" 
                
                onClick={toggle1}
                ></i>
            </h3>
            </Tooltip>
            </Fragment>:null
           }
             <Modal fade={true} isOpen={modal1} toggle={toggle1} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <span className='warningmodal'>Warning !</span>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to remove this record ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={toggle1}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={()=>{setLeadData(); DeleteLead()}}
          >
            Yes, Delete It!
          </button>
        </div>
      </ModalBody>
    </Modal>
             <form onSubmit={SubmitForm}>
               <div className="row">
               <div className="col-md-8 mt-4">
               <span id="heading1">Customer Details</span>
                   <div className="row mt-3">
                     <div className="col-md-4 mt-1">
                       <span className="d-flex justify-content-end" id="mandatory">Customer Name</span>
                     </div>
                     <div className="col-md-7">
                       <input
                         type="text"
                         name="customerName"
                         onChange={handleChange}
                         value={leadData.customerName}
                         className="form-control sm"
                         placeholder="FirstName , LastName"
                       />
                       {error && leadData.customerName==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Enter Customer Name
                                        </span>
                                    ) : (
                                        ""
                                    )}
                     </div>
                    {
                      leadData.customerType === "Individual" ? (<Fragment>
                         <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end" id="mandatory">Gender</span>
                       </div>
                       <div className="col-md-7 mt-3">
                       <div className="form-check form-check-inline">
                           <input
                             className="form-check-input"
                             type="radio"
                             name="gender"
                             id="inlineRadio7"
                             onChange={handleChange}
                             checked={leadData.gender === "Male"}
                             value="Male"
                           />
                           <label
                             className="form-check-label"
                             for="inlineRadio7"
                           >
                             Male
                           </label>
                         </div>
                         <div className="form-check form-check-inline">
                           <input
                             className="form-check-input"
                             type="radio"
                             name="gender"
                             id="inlineRadio8"
                             onChange={handleChange}
                             value="Female"
                             checked={leadData.gender === "Female"}
                           />
                           <label
                             className="form-check-label"
                             for="inlineRadio8"
                           >
                             Female
                           </label>
                         </div>
                         <div className="form-check form-check-inline">
                           <input
                             className="form-check-input"
                             type="radio"
                             name="gender"
                             id="inlineRadio9"
                             onChange={handleChange}
                             checked={leadData.gender === "Other"}
                             value="Other"
                           />
                           <label
                             className="form-check-label"
                             for="inlineRadio9"
                           >
                             Other
                           </label>
                         </div>
                         {error && leadData.gender ===""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Select Gender
                                        </span>
                                    ) : (
                                        ""
                                    )}
                       </div>
                      </Fragment>):null
                    }
                     <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end  ">Resident Type</span>
                         </div>
                         <div className="col-md-7 mt-2">
                         <select
                         class="form-select form-select-md"
                         aria-label=".form-select-md example"
                         name="residentTypeVO.residentTypeID"
                         onChange={handleChangeForResidentType}
                             value={leadData.residentTypeVO?.residentTypeID }
                       >
                         <option value="">Please Select</option>
                         {
                           residentType?.length > 0 ? <Fragment>
                             {
                               residentType.map((resident,index)=>{
                                 return(
                                   <option value={resident?.residentTypeID} key={index}>{resident.residentType}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                         }
                       </select>
                         </div>
                         <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end "id="mandatory">Lead Source</span>
                         </div>
                         <div className="col-md-7 mt-2">
                         <select
                         class="form-select form-select-md"
                         aria-label=".form-select-md example"
                         name="leadSourceVO.leadSourceID"
                         onChange={handleChangeForLeadSource}
                             value={leadData.leadSourceVO?.leadSourceID}
                       >
                         <option value="">Please Select</option>
                         {
                           leadSource?.length > 0 ? <Fragment>
                             {
                               leadSource.map((leadsource,index)=>{
                                 return(
                                   <option value={leadsource.leadSourceID} key={index}>{leadsource.leadSource}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                           }
                       </select>
                       {error && leadData.leadSourceVO?.leadSourceID === ""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Select Lead Source
                                        </span>
                                    ) : (
                                        ""
                                    )}
                         </div>
                     </div>
                     {/* <div className="row">
                       <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end" id="mandatory">Customer Type</span>
                       </div>
                       <div className="col-md-7 mt-2">
                           <select
                         class="form-select form-select-md"
                         aria-label=".form-select-md example"
                         name="customerTypeVO.customerTypeID"
                         onChange={handleChangeCustomerType}
                         value={leadData?.customerTypeVO?.customerTypeID}
                       >
                         <option value="">Please Select</option>
                         {
                           custTypes?.length > 0 ? <Fragment>
                             {
                               custTypes.map((type,index)=>{
                                 return(
                                   <option value={type?.customerTypeID} key={index}>{type?.customerType}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                           }
                       </select>
                       {error && leadData.customerTypeVO?.customerTypeID ==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Select Customer Type
                                        </span>
                                    ) : (
                                        ""
                                    )}
                       </div>
                     </div> */}
                     {
                      leadData.customerType == "Non-Individual" ?(
                         <div className="row ">
                           <div className="col-md-4 mt-3">
                           <span className="d-flex justify-content-end" >Director Name</span>
                         </div>
                         <div className="col-md-7 mt-2">
                           <input
                             type="text"
                             className="form-control sm"
                             placeholder="Enter Director Person Name"
                             name="directorName"
                         onChange={handleChange}
                         value={leadData?.directorName}
                           />
                         </div>
                         <div className="col-md-4 mt-3">
                           <span className="d-flex justify-content-end" >Contact Person </span>
                         </div>
                         <div className="col-md-7 mt-2">
                           <input
                             type="text"
                             className="form-control sm"
                             placeholder="Enter Contact Person Name"
                             name="contactPerson"
                         onChange={handleChange}
                         value={leadData?.contactPerson}
                           />
                         </div>
                         <div className="col-md-4 mt-3">
                         <span className="d-flex justify-content-end ">Contact Person Relation </span>
                           </div>
                           <div className="col-md-7 mt-2">
                             <select
                               class="form-select form-select-md"
                               aria-label=".form-select-md example"
                               name="contactPersonRelation"
                               onChange={handleChange}
                               value={leadData?.contactPersonRelation}
                             >
                               <option value=''>Please select</option>
                              <option value='Accoutant'>Accoutant</option>
                              <option value='Manager'>Manager</option>
                              <option value='Personal Assistant'>Personal Assistant</option>
                              <option value='Admin'>Admin</option>
                             </select>
                           </div>
                         </div>
                      
                      ):null
                     }
                     
               {leadData.customerType==="Individual" ? (
                <Fragment>

                
               <div className="row">
               <p id="heading2">Contact Details</p>
                     <div className="col-md-4 mt-2">
                     <span className="d-flex justify-content-end " id="mandatory">Primary Mobile Number</span>
                     </div>
                     <div className="col-md-7">
                       <input
                         type="number"
                         name="primaryNumber"
                         className="form-control"
                         placeholder="Enter  Mobile Number"
                         aria-label="Username"
                         aria-describedby="basic-addon1"
                         onChange={handleChange}
                         value={leadData.primaryNumber}
                       />
                         {/* {error && leadData.contactPerson ==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Enter Primary Number
                                        </span>
                                    ) : (
                                        ""
                                    )} */}
                     </div>
                     <div className="col-md-1" id="whatsapp">
                       {/* <h3>
                         <i class="bi bi-whatsapp"></i>
                       </h3> */}
                       <span className='mx-1' id='whatsapp'>
                         <i class="bi bi-whatsapp"></i>
                       </span>
                     </div>
                   </div>
                   <div className="row">
                     <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end  ">Secondary Mobile Number</span>
                     </div>
                     <div className="col-md-7 mt-2">
                       <input
                         type="number"
                         className="form-control"
                         placeholder="Enter  Mobile Number"
                         aria-label="Username"
                         aria-describedby="basic-addon1"
                         name="secondaryNumber"
                         onChange={handleChange}
                         value={leadData.secondaryNumber}
                       />
                     </div>
                     
                   </div>
                   <div className="row">
                     <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end  ">Email ID</span>
                     </div>
                     <div className="col-md-7 mt-2">
                       <input
                         type="emailID"
                         className="form-control"
                         placeholder="Enter Email ID"
                         aria-label="Username"
                         aria-describedby="basic-addon1"
                         name="emailID"
                         onChange={handleChange}
                         value={leadData.emailID}
                       />
                     </div>
                   </div>
                   </Fragment>
               ): null}
                   {/* {
                     leadData.customerType==="Non-Individual" ?(
                     <div className="row">
                       <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end" id="mandatory">Customer Contact Details</span>
                       </div>
                       <div className="col-md-7 mt-3">
                       <div className="form-check form-check-inline">
                           <input
                             className="form-check-input"
                             type="radio"
                             name="customerContactDetails"
                             id="inlineRadio3"
                             onChange={handleChange}
                             value={leadData?.customerContactDetails}
                             checked={leadData.customerContactDetails === "Same as above"}
                             onClick={()=>{setVisibleContPerson(false)}}
                           />
                           <label
                             className="form-check-label"
                             for="inlineRadio3"
                           >
                             Same as above
                           </label>
                         </div>
                         <div className="form-check form-check-inline">
                           <input
                             className="form-check-input"
                             type="radio"
                             name="customerContactDetails"
                             id="inlineRadio4"
                             onChange={handleChange}
                             value={leadData?.customerContactDetails}
                             checked={leadData.customerContactDetails === "Add separately"}
                             onClick={()=>{setVisibleContPerson(true)}}
                           />
                           <label
                             className="form-check-label"
                             for="inlineRadio4"
                           >
                             Add separately
                           </label>
                         </div>
                       </div>
                     </div>
                    ):null
                   } */}
                     {
                       leadData.customerType==="Non-Individual"? (
                       <div className="row">
                         <p id="heading2">Contact Details</p>
                       <div className="col-md-4 mt-2">
                             <span className="d-flex justify-content-end">Director Primary Mo No</span>
                             </div>
                             <div className="col-md-7 mt-1">
                               <input
                                 type="number"
                                 name="directorPrimaryNumber"
                                 className="form-control"
                                 placeholder="Enter  Mobile Number"
                                 aria-label="Username"
                                 aria-describedby="basic-addon1"
                                 onChange={handleChange}
                               value={leadData.directorPrimaryNumber}
                               />
                             </div>
                     <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end  ">Director Secondary Mo No</span>
                     </div>
                     <div className="col-md-7 mt-2">
                       <input
                         type="number"
                         className="form-control"
                         placeholder="Enter Mobile Number"
                         aria-label="Username"
                         aria-describedby="basic-addon1"
                         name='directorSecondaryNumber'
                         onChange={handleChange}
                         value={leadData.directorSecondaryNumber}
                       />
                     </div>
                     <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end  ">Director Email ID</span>
                     </div>
                     <div className="col-md-7 mt-2">
                       <input
                         type="email"
                         className="form-control"
                         placeholder="Enter Director Email ID"
                         aria-label="Username"
                         aria-describedby="basic-addon1"
                         name='directorEmailId'
                         onChange={handleChange}
                         value={leadData.directorEmailId}
                       />
                     </div>
                             <div className="col-md-4 mt-2">
                             <span className="d-flex justify-content-end"id="mandatory">Contact Person Primary Mo No</span>
                             </div>
                             <div className="col-md-7 mt-1">
                               <input
                                 type="number"
                                 name="contactPerson1stNumber"
                                 className="form-control"
                                 placeholder="Enter  Mobile Number"
                                 aria-label="Username"
                                 aria-describedby="basic-addon1"
                                 onChange={handleChange}
                               value={leadData.contactPerson1stNumber}
                               />
                                 {/* {error && leadData.contactPerson1stNumber ==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Enter Primary Number
                                        </span>
                                    ) : (
                                        ""
                                    )} */}
                             </div>
                             <div className="col-md-1 mt-2" id="whatsapp">
                       <span className='mx-1' id='whatsapp'>
                         <i class="bi bi-whatsapp"></i>
                       </span>
                     </div>
                     <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end  ">Conatct Person Secondary Mo No</span>
                     </div>
                     <div className="col-md-7 mt-2">
                       <input
                         type="number"
                         className="form-control"
                         placeholder="Enter Mobile Number"
                         aria-label="Username"
                         aria-describedby="basic-addon1"
                         name='contactPerson2ndNumber'
                         onChange={handleChange}
                         value={leadData.contactPerson2ndNumber}
                       />
                     </div>
                     <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end  ">Conatct Person Email ID</span>
                     </div>
                     <div className="col-md-7 mt-2">
                       <input
                         type="email"
                         className="form-control"
                         placeholder="Enter Contact Person Email ID"
                         aria-label="Username"
                         aria-describedby="basic-addon1"
                         name='contactPersonEmail'
                         onChange={handleChange}
                         value={leadData.contactPersonEmail}
                       />
                     </div>
                   
                           </div>):null
                     }
                     <div className="row">
                     <p id="headline3">Lead Details</p>
                     <div className="col-md-4 mt-2">
                     <span className="d-flex justify-content-end  "id="mandatory">Lead Type</span>
                       </div>
                       <div className="col-md-7 mt-2">

                       {
                              leadTypes?.map((types,i) =>{
                               return(
                                 < div className="form-check form-check-inline" key={i}>
                               
                                 <label className="form-check-label">
                                   <input
                                   
                                     type="radio"
                                     name="leadTypeVO.leadTypeID"
                                     className="form-check-input"
                                    onChange={handleChangeForLeadType}
                                    defaultChecked={types.leadTypeID === leadData.leadTypeVO?.leadTypeID}
                                     value={leadData.leadTypeVO?.leadTypeID}
                                   />
                                   
                                   {types.leadType}
                                 </label>
                                 {error && leadData.leadTypeVO.leadTypeID ==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Select Lead Type
                                        </span>
                                    ) : (
                                        ""
                                    )}
                               </div>
                               );
                     })
                          }
                     </div>
                     </div>
                     {
                       leadTyp?.length > 0   ?(
                       <div className="row">
                       <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end" id="mandatory"> Type</span>
                       </div>
                       <div className="col-md-7 mt-2">
                         <select
                         id=""
                           class="form-select form-select-md"
                           aria-label=".form-select-md example"
                           name="typesVO.typeId"
                            onChange={handleChangeForType}
                           value={leadData.typesVO?.typeId}
                         >
                            <option value="">Please Select</option>
                         {
                           leadTyp?.length > 0 ? <Fragment>
                             {
                               leadTyp.map((Types,index)=>{
                                 return(
                                   <option value={Types.typeId} key={index}>{Types.type}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                           }
                         </select>
                         {error && leadData.typesVO.typeId ==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please select Type
                                        </span>
                                    ) : (
                                        ""
                                    )}
                       </div>
                       {
                         subtypes!==null?
                           <Fragment>
                                <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end  ">Sub Type</span>
                       </div>
                       <div className="col-md-7 mt-2">
                         <select
                       id=""
                           class="form-select form-select-md"
                           aria-label=".form-select-md example"
                           name="subTypesVO.subTypeID"
                           onChange={handleChangeForSubType}
                           value={leadData.subTypesVO?.subTypeID}
                         >
                           <option value="">Please select</option>
                           {
                           subtypes?.length > 0 ? <Fragment>
                             {
                               subtypes.map((subtype,index)=>{
                                 return(
                                   <option value={subtype.subTypeID} key={index}>{subtype.subTypes}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                           }
                         </select>
                       </div> 
                           </Fragment>
                         : null
                       }
                       
                       
                       <div className="col-md-4 mt-3">
                       <span className="d-flex justify-content-end" id="mandatory">Amount</span>
                       </div>
                       <div className="col-md-7 mt-2">
                         <input
                           type="number"
                           name="amount"
                           className="form-control"
                           placeholder="Enter Loan Amount"
                           aria-label="Username"
                           aria-describedby="basic-addon1"
                           onChange={handleChange}
                           value={leadData.amount}
                         />
                         {error && leadData.amount ==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Enter Amount
                                        </span>
                                    ) : (
                                        ""
                                    )}
                       </div>
                     </div>
                       ):null
                     }
                    {
                      leadData.leadTypeVO?.leadTypeID === 1 ? (<Fragment>
                       <div className="row">
                     <p id="headline3">Case Details</p>
                     <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Partner
                          </span>
                        </div>
                        <div className="col-md-7 mt-2">
                          <select
                          style={{width:"430px"}}
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="partnerVO.partnerId"
                            onChange={handleChangeForPartner}
                            value={leadData.partnerVO?.partnerId}
                          >
                            <option value="">Please Select</option>
                            {partner?.length > 0 ? (
                                <Fragment>
                                  {partner.map((partner, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={partner?.partnerId}
                                      >
                                        {partner?.partnerName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                          </select>
                        </div>
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                          Channel Partner
                          </span>
                        </div>
                        <div className="col-md-7 mt-2">
                          <select
                          style={{width:"430px"}}
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="partnershipVO.partnershipId"
                            onChange={handleChangeForPartnerShip}
                            value={leadData.partnershipVO?.partnershipId}
                          >
                            <option value="">Please Select</option>
                            {partnerShip?.length > 0 ? (
                                <Fragment>
                                  {partnerShip.map((partner, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={partner?.partnershipId}
                                      >
                                        {partner?.partnershipName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                          </select>
                        </div>
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Project
                          </span>
                        </div>
                        <div className="col-md-7 mt-2">
                           <input
                            type="text"
                            name="projectName"
                            className="form-control"
                            placeholder="Enter Project Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChange}
                            value={leadData.projectName}
                          />
                        </div>
                        <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                              Bank
                            </span>
                          </div>
                          <div className="col-md-7 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="bankVO.bankId"
                              onChange={handleChangeForBank}
                              value={leadData.bankVO?.bankId ==="" ? leadData.bankVO.bankId = banks.bankId : leadData.bankVO?.bankId}
                            >
                              <option value="">Please Select</option>
                              {banks?.length > 0 ? (
                                <Fragment>
                                  {banks.map((bank, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={bank?.bankId}
                                      >
                                        {bank?.bankName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                              Branch
                            </span>
                          </div>
                          <div className="col-md-7 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="bankBranchVO.bankBranchId"
                              onChange={handleChangeForBankBranch}
                              value={leadData.bankBranchVO?.bankBranchId ==="" ? leadData.bankBranchVO.bankBranchId = bankBranch.bankBranchId : leadData.bankBranchVO?.bankBranchId}
                            >
                              <option value="">Please Select</option>
                              {bankBranch?.length > 0 ? (
                                <Fragment>
                                  {bankBranch.map((branch, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={branch?.bankBranchId}
                                      >
                                        {branch?.bankBranchName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                     </div>
                      </Fragment>):null
                    }
                     <div className="row">
                     <p  id="headline6" >Lead Owner</p>
                     <div className="col-md-4 mt-2">
                     <span className="d-flex justify-content-end  "id="mandatory">Lead Initiator</span>
                         </div>
                         <div className="col-md-7">
                         <select
                         class="form-select form-select-md"
                         aria-label=".form-select-md example"
                         name="initiatorVO.initiatorID}"
                         // onChange={handleBranch}
                         onChange= {handleChangeForInitiator}
                         value={leadData.initiatorVO?.initiatorID}
                       >
                         <option value="">Please Select</option>
                         {
                           leadInitiator?.length > 0 ? <Fragment>
                             {
                               leadInitiator.map((leadinitiator,index)=>{
                                 return(
                                   <option key={index} value={leadinitiator?.initiatorID}>{leadinitiator?.initiatorName}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                         }
                       </select>
                       {error && leadData.initiatorVO?.initiatorID ==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Select Initiatior
                                        </span>
                                    ) : (
                                        ""
                                    )}
                         </div>
                         <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end ">Branch </span>
                         </div>
                         <div className="col-md-7 mt-2">
                         
                        {
                         iniBranch?.initiatorID!==null ?(
                           <div>
                           <input
                          type="text"
                          name="branchVO.branchID"
                         
                          value={`${iniBranch.branchVO?.branch}`}
                          className="form-control sm"
                        />
                           </div>
                         ):null
                        }
                         </div>
                        
                         <div className="col-md-4 mt-3">
                     <span className="d-flex justify-content-end  ">Lead Owner</span>
                         </div>
                         <div className="col-md-7 mt-3">
                            {
                                getOwner?.userName==null ?(
                                  <Fragment>
                                     {/* <a href="#react" aria-hidden="true"className=" button mx-3" onClick={toggle}>
                                     <span id="assignowner">Assign Now</span>
                                     </a>    */}
                                      <Link
                  onClick={
                    NavigateTOAssignModal
                  }
                  className="disabledCursor"
                  id="assignowner"
                >
                  Assign Now
                </Link>
                                  </Fragment>
                                ):<Fragment>
                                  
                              {getOwner?.userName}
                               <Link onClick={NavigateTOAssignModal}className="disabledCursor" id="assignowner" >    Reassign</Link>
                                </Fragment>
                                
                            }   
                             <Modal isOpen={modal}  fade={true}   centered={true}>
                                    <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1"
                                     ><span id="assignowner1">Assign Lead</span></ModalHeader>
                                    <ModalBody>
                   <div className="container-fluid">
                     <div className="row">
                       <div className="col-md-12"><form>
                       <div className="row">
                       
                       <div className="col-md-4 mt-2">
                       <span className="d-flex justify-content-end  ">Lead Owner</span>
                         </div>
                         <div className="col-md-8">
                         <select
                         class="form-select form-select-md"
                         aria-label=".form-select-md example"
                         name="userVO.userId"
                         onChange= {handleChangeForOwner}
                         value={leadData.userVO?.userId}
                       > 
                         <option value="">Please select Owner</option>
                         {
                           user?.length > 0 ? <Fragment>
                             {
                               user.map((owner,index)=>{
                                 return(
                                   <option key={index} value={owner.userId}>{owner.userName}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                         }
                       </select>
                         </div>
                         </div>
                         <div className="row mt-4">
                       <div className="col-md-12">
                       <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                       <Button color="secondary"  id="btn-cancel" onClick={toggle}>Cancel</Button>
                       <Button color="primary"  onClick={toggle} id="btn-cancel">Assign Owner</Button>
                       </div>
                       </div>
                     </div>
                         </form>
                       </div>
                     </div>
                    
                    
                   </div> 
               </ModalBody>
           </Modal>  













             {/* <Modal isOpen={modal} id="assignmodal">
                  <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                    <span id="assignowner1">Assign Lead</span>
                  </ModalHeader>
                  <ModalBody>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={SubmitOwnerForm}>
                            <div className="row">
                            <div className="col-md-4 mt-2">
                       <span className="d-flex justify-content-end  ">Lead Owner</span>
                         </div>
                         <div className="col-md-8">
                         <select
                         class="form-select form-select-md"
                         aria-label=".form-select-md example"
                         name="ownerVO.ownerID"
                         onChange= {handleChangeForOwner}
                         value={leadData.ownerVO?.ownerID}
                       > 
                         <option value="">Please select Owner</option>
                         {
                           leadOwners?.length > 0 ? <Fragment>
                             {
                               leadOwners.map((owner,index)=>{
                                 return(
                                   <option key={index} value={owner.ownerID}>{owner.ownerName}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                         }
                       </select>
                         </div>
                              
                              
                              <div className="col-md-12 mt-4">
                                <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                  <Button
                                    color="secondary"
                                    id="btn-cancel"
                                    onClick={toggle}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    id="btn-cancel"
                                  >
                                    Assign Lead
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>           */}
                         </div>
                     
                     {/* <div className="row"> */}
                     <p id="headline5">General Discussion</p>
                     <div className="col-md-4 mt-2">
                    
                     <span className="d-flex justify-content-end " id="mandatory">GD Status</span>
                       </div>
                       <div className="col-md-7">
                        <select
                           class="form-select form-select-md"
                           aria-label=".form-select-md example"
                           name="gdStatusVO.gdStatusID"
                           // onChange={(e)=>(handleshowGDLink(e))}
                           onChange= {handleChangeForGdDtatus}
                         value={leadData.gdStatusVO?.gdStatusID}
                         >
                           <option value="">Please select</option>
                           {
                           status?.length > 0 ? <Fragment>
                             {
                               status.map((status,index)=>{
                                 return(
                                   <option key={index} value={status?.gdStatusID}>{status?.gdStatus}</option>
                                   
                                 );
                               })
                             }
                           </Fragment>:null
                         }
                           {/* <option value="Pending">Pending</option>
                           <option value="In-Progress">In-Progress</option>
                           <option value="Completed">Completed</option> */}
                           
                         </select>
                         {error && leadData.gdStatusVO.gdStatusID ==""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Please Select GD Status
                                        </span>
                                    ) : (
                                        ""
                                    )}
                       </div>
                      
                     {/* </div> */}
                     {
                       leadData.gdStatusVO?.gdStatus != "Pending" &&  (
                       <div className="row">
                           <div className="col-md-4 mt-3">
                           <span className="d-flex justify-content-end  ">Google Form Link</span>
                       </div>
                       <div className="col-md-7 mt-2">
                       <input
                        id="gdlink"
                         type="text"
                         className="form-control md"
                         placeholder="Enter Google Form Link"
                         name="gdLink"
                         onChange= {handleChange}
                         value={leadData.gdLink}
                       />
                       </div>
                       </div>
                       )
                     }
                     {/* {
                       leadData.gdStatusVO?.gdStatusID==="3" &&(
                       <div className="row">
                           <div className="col-md-4 mt-3">
                           <span className="d-flex justify-content-end  ">Google Form Link</span>
                       </div>
                       <div className="col-md-7 mt-2">
                       <input
                        id="gdlink"
                         type="text"
                         className="form-control md"
                         placeholder="Enter Google Form Link"
                         name="gdLink"
                         onChange= {handleChange}
                         value={leadData.gdLink}
                       />
                       </div>
                       </div>
                       )
                     } */}
                     {/* <div className="row"> */}
                     <p id="headline4">Log Notes</p>
                     <div className="col-md-4 mt-1">
                     <span className="d-flex justify-content-end">Lead Notes</span>
                       </div>
                       <div className="col-md-7">
                       <div className="form-group">
                              <textarea
                              name="leadNotes"
                              className="form-control"
                              maxLength="300"
                              rows={3}
                              value={leadData.leadNotes}
                              onChange={handleChange}
                             />
                            </div>
                            <div className=" d-md-flex justify-content-md-end"> Remaining Characters:  {300-char}/{characterLimit} </div>
                       </div> 
                     </div>
                     {/* </div> */}
                     <div className="row mt-3 mb-3">
                     <div className="col-md-12">
                       <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                         <button
                         onClick={navigateToCreateLead}
                           class="btn btn-secondary me-md-2"
                           id="Cancelbtn1"
                           type="button"
                         >
                           Cancel
                         </button>
                         <button class="btn btn-primary" type="submit"  id="Cancelbtn2">
                           Update Lead
                         </button>
                       </div>
                     </div>
                   </div>
                     </div>
               </div>
             </form>
           </div>
         </div>
       </div>
     </div>
}
   </Fragment>
    
  )
}

