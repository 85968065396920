import React, { Fragment, useMemo, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { useState, useEffect } from "react";
import TableContainer from "../../../../Components/Common/TableContainer";
import {
  Col,
  Container,
  Card,
  row,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import leftChevron from "../../../../assets/images/profileimg.png";
import pdfimg from "../../../../assets/images/pdfimg.svg";
import editicon from "../../../../assets/images/editIcon.png";
import setting from "../../../../assets/images/setting.png";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Table,
} from "reactstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PreviewCardHeader from "../../../../Components/Common/PreviewCardHeader";
import ViewLoanCase from ".";
import ViewCase from "../ViewCase";
const initialValues = {
  taskStatus: {
    taskStatusId: "",
  },
};

export default function ApnaRupee(props) {
 console.log("Received state from parent: :",props.ownerList);
  const { LoanCaseId } = useParams();
  const [owner, setOwner] = useState([]);
  const[showOwner,setShowOWner]=useState([]);
  const [startDates,SetStartdates]=useState({
    startDate:'',
    id:''
  })
  const [plannedEndDates,SetPlannedEndDates]=useState({
    planedEndDate:'',
    id:''
  })
  const [endDates,SetEnddates]=useState({
    endDate:'',
    id:''
  })
  const [templateTaskData , setTemplateTaskData]=useState({
    userVO:{
      userId:''
    },
    assingedBy:{
      userId:''
    },
    id:'',
  });
  useEffect( () => {
    getAllOwnerList()
    //setShowOWner(props.ownerList);
  },[]);
  useEffect(() => {
    console.log("Owner list 2 :",owner);
    console.log("Owner list 02 :",showOwner);
  },[])
  console.log("Owner showOwner 05 :",showOwner);
  const [isSubmitted, setSubmitted] = useState(false);
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (values) => {
        setSubmitted(true);
        toast.success("Lead created successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    });

  var renderTooltip = <span>Add More Applicants</span>;
  const [visible, setVisible] = useState(false);
  const navigateToCreateLead = () => {
    navigate("/AddLead");
  };

  const navigateToInsuranceTask = () => {
    navigate(`/insuranceTask`);
  };

  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);

  // useEffect(() => {
  //     ProductService.getProductsWithOrdersSmall().then((data) => setProducts(data));
  // }, []);

  //const { LoanCaseId } = useParams();
  //console.log("loanCaseId", LoanCaseId);
  const authUser = JSON.parse(localStorage.getItem("user"));
  const [task, settask] = useState([]);
  const Initiator = JSON.parse(localStorage.getItem('user'));
  const initID =Initiator.userID;
 
  const getAllOwnerList= ()=>{
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllUsers`;
      axios
      .get(d6)
      .then((response) => {
        console.log("Owner list 0 :", response.data);
        setOwner(response.data);
        setShowOWner(response.data);
        console.log("Owner list 1 :",owner)
        console.log("Owner list 01 :",showOwner)
      })
      .catch((err) => {
        console.log(err);
      });
      console.log("Owner list 03 :",showOwner);
  }
 

  const initiID = templateTaskData?.assingedBy?.userId;
    console.log("initiIDi", initiID);

    const [iniBranch, setIniBranch] = useState({});
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getUserById?userId=${initiID}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setIniBranch(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [initiID]);


    const handleChangeForInitiator =(e)=>{
      setTemplateTaskData({
          ...templateTaskData,
          assingedBy: {
            userId:e.target.value
            },
      })
    }
  const [getCustomers, setGetCustomers] = useState([]);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllLoanCaseTaskByLoanCaseId?loanCaseID=${LoanCaseId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setProducts(response.data);
        setGetCustomers(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 // console.log("task", products);
  const expandAll = () => {
    let _expandedRows = {};

   // products.forEach((p) => (_expandedRows[`${p.taskId}`] = true));

    setExpandedRows(_expandedRows);
  };

  const onRowExpand = (event) => {
    toast.current.show({
      severity: "info",
      summary: "Product Expanded",
      detail: event.data.name,
      life: 3000,
    });
  };

  const onRowCollapse = (event) => {
    toast.current.show({
      severity: "success",
      summary: "Product Collapsed",
      detail: event.data.name,
      life: 3000,
    });
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const allowExpansion = (rowData) => {
    return rowData.subTask.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.subTask} className="child">
          <Column field="subTaskId"></Column>
          <Column field="subTaskId"></Column>
          <Column field="" style={{ width: "9rem", resize: "none" }}></Column>
          <Column
            field="subTaskName"
            style={{ width: "14rem", resize: "none" }}
          ></Column>
          <Column
            field="caseOwner.ownerName"
            style={{ width: "6rem", resize: "none" }}
          ></Column>
          <Column
            field="taskStatus.taskStatus"
            style={{ width: "6rem", resize: "none" }}
          ></Column>
          <Column
            field="startDate"
            style={{ width: "11rem", resize: "none" }}
          ></Column>
          <Column
            field="plannedEndDate"
            style={{ width: "11rem", resize: "none" }}
          ></Column>

          <Column field="" style={{ width: "6rem", resize: "none" }}></Column>
          <Column field="" style={{ width: "6rem", resize: "none" }}></Column>
          <Column field="" style={{ width: "6rem", resize: "none" }}></Column>
        </DataTable>
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap justify-content-center gap-2">
      <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text />
      <Button
        icon="pi pi-minus"
        label="Collapse All"
        onClick={collapseAll}
        text
      />
    </div>
  );

  //console.log("getCustomers=======", getCustomers);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/customer/getAllTaskStatus`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        settask(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate();
  const navigateToCustomermanagement = () => {
    navigate("/customers");
  };
  const navigateToAddApplicant = () => {
    navigate(`/addApplicantDetails/${LoanCaseId}`);
  };
  const navigateToEditApplicant = () => {
    navigate(`/EditApplicantDetails/${LoanCaseId}`);
  };

  const navigateToAddContact = () => {
    navigate(`/addContactDetails/${LoanCaseId}`);
  };
  const navigateToAddKYC = () => {
    navigate(`/addKycDetail/${LoanCaseId}`);
  };
  const navigateToAddAddress = () => {
    navigate(`/addAddressDetail/${LoanCaseId}`);
  };

  const navigateToAddDocuments = () => {
    navigate(`/addDocuments/${LoanCaseId}`);
  };
  const navigateToAddTask = () => {
    navigate(`/addTask/${LoanCaseId}`);
  };

  const navigateToEditCaseDetails = () => {
    navigate(`/EditCaseDetails/${LoanCaseId}`);
  };

  const navigateToEditContact = () => {
    navigate(`/EditContactDetails/${LoanCaseId}`);
  };

  const [isSetting, setIsSetting] = useState(false);
  const[templateTaskStatus,setTemplateTaskTStatus]=useState({
    taskStatus:{
      taskStatusId:''
    },
    id:'',
  });
  const [viewCase, SetViewCase] = useState({});

  
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        SetViewCase(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanCaseId]);
  //console.log("ViewLoanCase", viewCase);
  const templateId = viewCase.template?.templateId;
  //console.log("templateId", templateId);
  const [template, setTemplate] = useState([]);
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/master/getAllTaskByTemplateId?templateId=${templateId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response template=============== :", response.data);
        setTemplate(response.data);
        // localStorage.setItem('caseTempateTask',JSON.stringify(response.data));
        // assignLoanCAseId();
      })
      .catch((err) => {
        console.log(err);
      });
  },[LoanCaseId]);
  // const storeLocalStorage =()=>{
    
    //assignLoanCAseId();
  //}
 
// const assignLoanCAseId =()=>{
//   const data = window.localStorage.getItem('caseTempateTask')
//       const newData = JSON.parse(data)
//       console.log("newData",newData);
  
//       for (var i = 0; i <= newData.length+1; i++) {
//         if(newData[i]?.loanCaseId != null || newData[i]?.loanCaseId != undefined ||newData[i]?.loanCaseId != 0){  
//           newData[i].loanCaseId = LoanCaseId;  
//           console.log("djnsjbnis======" ,newData[i].loanCaseId);
//         }
//         }
//       localStorage.setItem('caseTempateTask',JSON.stringify(newData));
  
// }
//console.log("TemplateData in for loop 2 ===", template);

  const [tempData, setTempData] = useState({});
  useEffect(() => {
    dataOfTemplate();
  },[LoanCaseId,templateTaskData,templateTaskStatus,startDates,endDates,plannedEndDates]);

  useEffect(() => {
    dataOfTemplate();
  },[LoanCaseId]);


const dataOfTemplate=()=>{
  const dataUrl = `https://pradhanportal.com/loanApplication/master/getAllTemplateTaskByLoanCaseId?loancaseId=${LoanCaseId}`;
  axios
    .get(dataUrl)
    .then((response) => {
      console.log("response  getAllTemplateTaskByLoanCaseId:", response.data);
      setTempData(response.data);
     
    })
    .catch((err) => {
      console.log(err);
    });
}

 const addCaseTemplateTaskData=()=>{
  console.log("add CaseTemplateTaskData",template)
  
    template.map((item) => {
      if (item?.loanCaseId === 0 || item?.loanCaseId === null || item?.loanCaseId === undefined) {
        return (  item.loanCaseId = LoanCaseId );
      } else {
        return item;
      }
    })
    const dataUrl = `https://pradhanportal.com/loanApplication/master/addTaskForTemplate`;
  axios.post(dataUrl,template).then((response)=>{
    console.log(response);
  }).catch((err)=>{
    console.error(err);
  });
  console.log("CaseTemplateTaskData After",template)
 
 }

  
   
  

  const [applicant, setApplicant] = useState({});
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/customer/getAllApplicantByLoanCaseID?loanCaseID=${LoanCaseId}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setApplicant(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanCaseId]);
  //console.log("applicant", applicant);

  var renderTooltip1 = <span>Add Contact Details</span>;
  var renderTooltip2 = <span>Add KYC Details</span>;
  var renderTooltip3 = <span>Add Address Details</span>;
  var renderTooltip4 = <span>Add More Documents</span>;
  var renderTooltip5 = <span>Edit Case Details</span>;
  var renderTooltip6 = <span>Edit Applicant Details</span>;
  var renderTooltip7 = <span>Edit Conatct Details</span>;
  var renderTooltip8 = <span>Edit KYC Details</span>;
  var renderTooltip9 = <span>Edit Permanent Address</span>;
  var renderTooltip10 = <span>Edit Site Address</span>;
  var renderTooltip20 = <span>Edit Current Address</span>;
  var renderTooltip11 = <span>Edit Office Address</span>;
  var renderTooltip12 = <span>Delete Document</span>;
  var renderTooltip21 = <span>Add New Task</span>;
  const [status, setStatus] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState({
    id:'',
    templateId: "",
    loanCaseId: LoanCaseId,
    templateTaskId: "",
    loanStage: {
      loanStageID: "",
    },
    loanTaskVO: {
      loanTaskId: "",
    },
    subTaskVO: {
      subTaskId: "",
    },
    ownerVO: {
      ownerID: '',
    },
    taskStatus: {
      taskStatusId: "",
    },
    startDate: "",
    plannedEndDate: "",
    duration: "",
    createdBy: "",
    actualEndDate: "",
    taskId: "",
  });


  // const loadOwner =  () => {
  //   const result =  axios.get(`https://pradhanportal.com/loanApplication/master/getAllOwner`);
  //   setOwner(result.data);
  // };
 // console.log("owner", owner);
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/customer/getAllTaskStatus`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setStatus(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeFortaskstatus = (e ,id) => {
   // e.preventDefault();
   // console.log("e.target.value :",e?.target?.value);
    // setTemplateTaskTStatus({
    //  // ...templateTaskStatus,
    //   taskStatus: {
    //     taskStatusId: e.target.value,
    //   },
    //   id:id,
    // });
    templateTaskStatus.taskStatus.taskStatusId=e?.target?.value;
    templateTaskStatus.id=id;
     if(templateTaskStatus?.taskStatus.taskStatusId !=null || templateTaskStatus?.taskStatus.taskStatusId != undefined || templateTaskStatus?.taskStatus.taskStatusId !="" || templateTaskStatus?.taskStatus.taskStatusId !=0){
       updateTaskStatus();
     }
  };
  const setTaskIdForStatusTemp = (e, templateTaskId) => {
    setUpdatedStatus({
      ...updatedStatus,
      taskStatus: {
        taskStatusId: e.target?.value,
      },
    });
    updatedStatus.templateTaskId = templateTaskId;
    if (updatedStatus.templateTaskId != null) {
      updateTaskStatusTemp();
    }
  };
  const setTaskIdForStatus = (e, taskId) => {
    setUpdatedStatus({
      ...updatedStatus,
      taskStatus: {
        taskStatusId: e.target?.value,
      },
    });
    updatedStatus.taskId = taskId;
    if (updatedStatus.taskId != null) {
      updateTaskStatus();
    }
  };

  const setTaskIdForCaseOwner = (e, id) => {
   // e.preventDefault();
    console.log("e.target.value :",e.target.value);
      templateTaskData.userVO.userId=e.target.value;
// templateTaskData.assingedBy.userId=e.target.value;
      templateTaskData.id=id;
      //})
      console.log("Update Template Task Owner updatedOwner 2:",templateTaskData)
      if (templateTaskData.id != null || templateTaskData.id != "" || templateTaskData.id != undefined) {
      updateTaskOwner();
    }
  };
  const setTaskIdForCaseAssingOwner = (e, id) => {
    // e.preventDefault();
     console.log("e.target.value :",e.target.value);
       templateTaskData.userVO.userId=e.target.value;
       templateTaskData.assingedBy.userId=e.target.value;
       templateTaskData.id=id;
       //})
       console.log("Update Template Task Owner updatedOwner 2:",templateTaskData)
       if (templateTaskData.id != null || templateTaskData.id != "" || templateTaskData.id != undefined) {
       updateTaskOwner();
     }
   };
  const updateTaskOwner =()=>{
   // alert(JSON.stringify(updatedStatus));
    //alert("updated")
    console.log("Update Status Data updatedStatus in updateTaskOwner:",templateTaskData)
    axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForCase',templateTaskData).then((response)=>{
            console.log(response);
          }).catch((err)=>{
            console.error(err);
          });
  }
  console.log("Status", updatedStatus.taskStatus?.taskStatusId);
  const updateTaskStatusTemp =()=>{
    //alert(JSON.stringify(updatedStatus));
    //alert("updated")
  }
  const updateTaskStatus = () => {
    console.log("Update Status Data updatedStatus in updateTaskOwner:",templateTaskStatus)
    axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForTaskStatus',templateTaskStatus).then((response)=>{
      console.log(response);
    }).catch((err)=>{
      console.error(err);
    });
  };
 const handleChangeForStartDates =(e,id)=>{
   console.log("e.target.value :",e.target.value);
  startDates.startDate=e.target.value;
  startDates.id=id;
   if (startDates.id != null || startDates.id != "" || startDates.id != undefined) {
    updateStartDate();
  }
 }

 const updateStartDate =()=>{
  console.log("Update Start Date :",startDates)
  axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForDates',startDates).then((response)=>{
    console.log(response);
  }).catch((err)=>{
    console.error(err);
  });
}
 const handleChangeForplannedEndDate =(e,id)=>{
  console.log("e.target.value :",e.target.value);
 plannedEndDates.planedEndDate=e.target.value;
 plannedEndDates.id=id;
  if (plannedEndDates.id != null || plannedEndDates.id != "" || plannedEndDates.id != undefined) {
    updatePlannedEndDate();
 }
}
const updatePlannedEndDate =()=>{
  console.log("Update Start Date :",plannedEndDates)
  axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForPlannedDates',plannedEndDates).then((response)=>{
    console.log(response);
  }).catch((err)=>{
    console.error(err);
  });
}
const handleChangeForEndDate =(e,id)=>{
  console.log("e.target.value :",e.target.value);
  endDates.endDate=e.target.value;
  endDates.id=id;
  if (endDates.id != null || endDates.id != "" || endDates.id != undefined) {
    updateEndtDate();
 }
}
const updateEndtDate =()=>{
  console.log("Update Start Date :",endDates)
  axios.put('https://pradhanportal.com/loanApplication/master/editTemplateTaskForEndDates',endDates).then((response)=>{
    console.log(response);
  }).catch((err)=>{
    console.error(err);
  });
}
  const columns = useMemo(
    () => [
      {
        Header: "Sr No",
        Cell: (data) => {
          return (
              (data.row.index + 1)
              
          );
        },
      },

      {
        Header: "Task ID",
        accessor: "id",
        // Cell: (data) => {
        //   return (
        //     <Link
        //       id="leadid1"
        //       to={`/viewTaskDetails/${data?.row.original.taskId}`}
        //     >
        //       {data?.row.original.generatedId}
        //     </Link>
        //   );
        // },
      },

      {
        Header: "Stage",
        accessor: "loanStage.loanStage",
      },
      {
        Header: "Task",
        accessor: "loanTaskVO.loanTask",
      },
      {
        Header: "subTask",
        accessor: "subTaskVO.subTaskName",
      },
      {
        Header: "Task Owner",
       // accessor: "caseOwner.ownerName",
        Cell: (data) => (
          <>

           {JSON.stringify(showOwner)}
             <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        style={{width:"150px"}}
        aria-label=".form-select-sm example"
        //name="ownerVO.ownerID"
        onChange={(e) => {
          
          setTaskIdForCaseOwner(e,data?.row.original.id)}}
        value={data.row.original.ownerVO?.ownerID}
      >
        <option value="">Please Select</option>
        {showOwner?.length > 0 ? (
          <Fragment>
            {showOwner.map((taskStatus, index) => {
              return (
                <option value={taskStatus.ownerID} key={index}>
                  {taskStatus.ownerName}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
          </>
        ),
      },
      {
        Header: "Task Status",
       // accessor: "taskStatus.taskStatus",
        Cell: (data) => (
          <>
              <select
            //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
                      class="form-select form-select-sm"
                      style={{width:"150px"}}
                      aria-label=".form-select-sm example"
                    //  name='taskStatus.taskStatusId'
                      onChange={(e) => {
                        // setTemplateTaskTStatus({
                        //   ...templateTaskStatus,
                        //   taskStatus:{
                        //     taskStatusId:e.target.value,
                        //   }
                        // });
                        handleChangeFortaskstatus(e,data?.row.original.id)}}
                      value={data?.row.original.taskStatus?.taskStatusId}
                    >
                      <option value="">Please Select</option>
                      {
                        status?.length > 0 ? <Fragment>
                          {
                            status?.map((taskStatus,index)=>{
                              return(
                                <option value={taskStatus.taskStatusId}key={index}>{taskStatus.taskStatus}</option>

                              );
                            })
                          }
                        </Fragment>:null
                      }
                    </select>
          </>
        ),
      },
      {
        Header: "Start Date",
        // accessor: (d) => {
        //   return moment(d.startDate).format("DD-MMM-YYYY");
        // },
        Cell: (data) => (
          <>
            <input
              type="Date"
              name="startDate"
              className="form-control form-control-sm"
              placeholder="Enter Task Name"
            />
          </>
        ),
        filterable: false,
      },
      {
        Header: "Planned End Date",
        // accessor: (d) => {
        //   return moment(d.plannedEndDate).format("DD-MMM-YYYY");
        // },
        Cell: (data) => (
          <>
            <input
              type="Date"
              name="startDate"
              className="form-control form-control-sm"
              placeholder="Enter Task Name"
            />
          </>
        ),
        filterable: false,
      },
      {
        Header: "Duration",
        // accessor:"duration",
        Cell: (data) => {
          return (
            // ({data?.row.original.duration}-Days)
            <Fragment>{data?.row.original?.duration} Day</Fragment>
          );
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
      },
      {
        Header: "Actual End Date",
        Cell: (data) => (
          <>
            <input
              type="Date"
              name="startDate"
              className="form-control form-control-sm"
              placeholder="Enter Task Name"
            />
          </>
        ),
        filterable: false,
      },
    ],
    []
  );

  const createtaskSubtask = (rowData) => {
    <UncontrolledDropdown>
      {rowData + 1}
      <DropdownToggle
        href="#"
        className="btn btn-soft-light btn-sm"
        tag="button"
      >
        <img src={setting} width="15" height="15" alt="" className="mx-4" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          href="#"
          // onClick={() => {
          //   const productData = cellProps.row.original;
          //   onClickDelete(productData);
          // }}
        >
          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
          Delete Task
        </DropdownItem>
        <DropdownItem href="/addTask">
          <i className="ri-eye-fill align-bottom me-2 text-muted"></i> Create
          Task Above
        </DropdownItem>
        <DropdownItem href="/addTask">
          <i className="ri-eye-fill align-bottom me-2 text-muted"></i> Create
          Task Below
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>;
  };

  const startDateTemplate = (rowData) => {
    return moment(rowData.startDate).format("DD-MMM-YYYY");
  };

  const plannedDateTemplate = (rowData) => {
    return moment(rowData.startDate).format("DD-MMM-YYYY");
  };

  const durationTemplate = (rowData) => {
    return  (rowData.planedEndDate != null ? rowData.duration+1 : 0);
  };

  const endDateTemplate = (rowData) => {
    return moment(rowData.startDate).format("DD-MMM-YYYY");
  };
  const [isDisabledOwner, setIsDisabledOwner] = React.useState(false);
  const [isDisabledstartDate, setIsDisabledStartDate] = React.useState(false);
  const [isDisabledEndDate, setIsDisabledEndDate] = React.useState(false);
  const [isDisabledStatus, setIsDisabledStatus] = React.useState(false);
  useEffect(() => {
    if(authUser.role?.role === "Sales Executive" || authUser.role?.role === "Sales Manager" || authUser.role?.role === "Executive"){
      setIsDisabledOwner(!isDisabledOwner)
      setIsDisabledStartDate(!isDisabledstartDate)
      setIsDisabledEndDate(!isDisabledEndDate)
    }
    
  },[]);
  const CaseOwner = (rowData) => {
    return (
      <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        aria-label=".form-select-sm example"
        name="userVO.userId"
        disabled={authUser.role?.role === "Local Admin" ? false:true}
         onChange={(e) => { setTaskIdForCaseOwner(e,rowData.id,rowData)}}
        value={rowData.userVO?.userId}
      >
        <option value="">Please Select</option>
        {owner?.length > 0 ? (
          <Fragment>
            {owner.map((taskStatus, index) => {
              return (
                <option value={taskStatus?.userId} key={index}>
                  {taskStatus.userName}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
    );
  };

  const assingByTemp = (rowData) => {
    return (
      <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        aria-label=".form-select-sm example"
        name="userVO.userId"
         onChange={(e) => { setTaskIdForCaseAssingOwner(e,rowData.id,rowData)}}
        // value={rowData.userVO?.userId}
        value={rowData.assingedBy?.userId === "" ||rowData.assingedBy?.userId === null ? rowData.assingedBy.userId = Initiator.userID : rowData.assingedBy?.userId}
      >
        <option value="">Please Select</option>
        {owner?.length > 0 ? (
          <Fragment>
            {owner.map((taskStatus, index) => {
              return (
                <option value={taskStatus?.userId} key={index}>
                  {taskStatus.userName}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
    );
  };
  const startDateTemp = (rowData) => {
    return (
      <input
        type="Date"
        name="startDate"
        disabled={isDisabledstartDate}
        className="form-control form-select-sm"
        placeholder="Enter Task Name"
       value={rowData?.startDate !=="" ? moment(rowData?.startDate).format('YYYY-MM-DD') : ""}
       onChange={(e) => { handleChangeForStartDates(e,rowData.id,rowData);}}
      />
    );
  };
  const plannedDateTemp = (rowData) => {
    return (
      <input
        type="Date"
        name="planedEndDate"
        disabled={isDisabledEndDate}
        className="form-control form-select-sm"
        placeholder="Enter Task Name"
        value={rowData?.planedEndDate !==null ? moment(rowData?.planedEndDate).format('YYYY-MM-DD') : ""}
        onChange={(e) => { handleChangeForplannedEndDate(e,rowData.id,rowData);}}
      />
    );
  };
  const endDateTemp = (rowData) => {
    return (
      <input
        type="Date"
        name="endDate"
        className="form-control form-select-sm"
        placeholder="Enter Task Name"
        value={rowData?.endDate !==null ? moment(rowData?.endDate).format('YYYY-MM-DD') : ""}
        onChange={(e) => { handleChangeForEndDate(e,rowData.id,rowData);}}
      />
    );
  };

  const getColor = (taskStatusId) => {
    switch (taskStatusId) {
        case 1:
            return 'open';
        case 2:
            return 'Inprogress';
        case 3:
            return 'hold';
            case 4:
            return 'close';
            case 5:
            return 'cancel';
        default:
            return 'black'; // default color
    }
};
  const statusTemp = (rowData) => {
    console.log("rowData+++++++++========",rowData.userVO?.userId);
    return (
      <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        aria-label=".form-select-sm example"
        name="taskStatus.taskStatusId"
        disabled={rowData.userVO?.userId === authUser.userID || authUser.role?.role === "Local Admin" ? false:true}
        onChange={(e) => {
        setTemplateTaskTStatus({
            ...templateTaskStatus,
            taskStatus:{
            taskStatusId:e.target.value,
            },
            id:rowData.id,
        })
          handleChangeFortaskstatus(e, rowData.id);
        }}
        value={rowData.taskStatus?.taskStatusId}
        className={getColor(rowData?.taskStatus?.taskStatusId)}
      >
        <option value=""className="bg-light text-dark">Please Select</option>
        {status?.length > 0 ? (
          <Fragment>
            {status?.map((taskStatus, index) => {
              return (
                <option value={taskStatus?.taskStatusId} key={index} className="bg-light text-dark">
                  {taskStatus?.taskStatus}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
    );
  };
  const statusTemplate = (rowData) => {
    return (
      <select
        //  data?.row.original.taskStatus?.taskStatusId != null ? data?.row.original.taskStatus?.taskStatus : 1
        class="form-select form-select-sm"
        aria-label=".form-select-sm example"
        name="taskStatus.taskStatus"
        onChange={(e) => {
          setUpdatedStatus({
            ...updatedStatus,
            taskStatus: {
              taskStatusId: e.target.value,
            },
          });
          setTaskIdForStatus(e, rowData.taskId);
        }}
        value={rowData.taskStatus?.taskStatusId}
        className={getColor(rowData?.taskStatus?.taskStatusId)}
      >
        <option value=""  className="bg-light text-dark">Please Select</option>
        {status?.length > 0 ? (
          <Fragment>
            {status?.map((taskStatus, index) => {
              return (
                <option value={taskStatus?.taskStatusId} key={index}  className="bg-light text-dark">
                  {taskStatus?.taskStatus}
                </option>
              );
            })}
          </Fragment>
        ) : null}
      </select>
    );
  };
  const navigateToCreateAboveTask = (sequenceNo, loanCaseID) => {
    navigate(`/addTaskAbove/${sequenceNo},${loanCaseID}`);
  };

  const navigateToCreateBelowTask = (sequenceNo, loanCaseID) => {
    navigate(`/addTaskBelow/${sequenceNo},${loanCaseID}`);
  };
  const navigateToViewTask = (rowData) => {
    return (
      <Link id="leadid1" to={`/viewTaskDetails/${rowData?.taskId}`}>
        {rowData?.generatedId}
      </Link>
    );
  };

  const customerID = viewCase.customerId;
  console.log("customerID", customerID);
  const [customer, setCustomer] = useState({});
  useEffect(() => {
    const dataUrl = `https://pradhanportal.com/loanApplication/customer/getCustomerById?customerId=${customerID}`;
    axios
      .get(dataUrl)
      .then((response) => {
        console.log("response :", response.data);
        setCustomer(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customerID]);
  console.log("customer", customer);

  const navigateToEditPermanentAddress = () => {
    localStorage.setItem("LoanCaseId", LoanCaseId);
    navigate(`/EditPermanentAddress/${customerID}`);
  };

  const navigateToEditCurrentAddress = () => {
    localStorage.setItem("LoanCaseId", LoanCaseId);
    navigate(`/EditCurrentAddress/${customerID}`);
  };

  const navigateToEditOfficeAddress = () => {
    localStorage.setItem("LoanCaseId", LoanCaseId);
    navigate(`/EditOfficeAddress/${customerID}`);
  };
  const navigateToEditSiteAddress = () => {
    navigate(`/EditSiteAddress/${LoanCaseId}`);
  };
  const expandedTemplate = (data) => {
    return (
      <div>
        {data.subTask.map((item, index) => {
          return (
            <div className="row" key={index}>
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table className="caption-top table-nowrap mb-0 table-hover">
                    <tbody>
                      <tr>
                        <td className="col-md-1">
                          {/* <span className="subTaskOwner">
                            {item.caseOwner.ownerName}
                          </span> */}
                        </td>
                        <td className="col-md-1">
                          <Link
                            id="leadid1"
                            to={`/viewTaskDetails/${item?.subTaskId}`}
                          >
                            <span className="text-start">
                              1000{item?.subTaskId}
                            </span>
                          </Link>
                          {/* 1000{item.subTaskId} */}
                        </td>
                        <td className="col-md-2 text-center">
                          <span className="subTaskCreatedBy">
                            {item.subTaskName}
                          </span>
                        </td>
                        <td className="col-md-1">
                          <span className="subTaskOwner">
                            {item.caseOwner.ownerName}
                          </span>
                        </td>
                        <td className="col-md-1 text-center">
                          <select
                            className="form-select form-select-sm subTaskStatus"
                            aria-label=".form-select-sm example"
                            name="taskStatus.taskStatus"
                            onChange={handleChangeFortaskstatus(
                              item?.subTaskId
                            )}
                            value={item.taskStatus?.taskStatusId}
                          >
                            <option value="">Please Select</option>
                            {status?.length > 0 ? (
                              <Fragment>
                                {status.map((taskStatus, index) => {
                                  return (
                                    <option
                                      value={taskStatus?.taskStatusId}
                                      key={index}
                                    >
                                      {taskStatus.taskStatus}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </td>
                        <td className="col-md-1 text-center">
                          {" "}
                          <span className="subTaskStartDate">20-Jan-2024</span>
                        </td>
                        <td className="col-md-1 text-center">
                          <span className="subTaskEndDate">21-Jan-2024</span>
                        </td>
                        <td className="col-md-1 text-center">
                          <span className="subTaskDuration">2 Days</span>
                        </td>
                        <td className="col-md-1">
                          <span className="subTaskCreatedBy">Vinay Varma</span>
                        </td>
                        <td className="col-md-1">
                          <span className="subTaskActualEndDate">
                            22-Jan-2024
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
 
 
  const disDate = viewCase.disbursementDate;
  const disbursementDate = moment(disDate).format("DD MMM YYYY");

  const createdOn = viewCase.sanctionDate;
  const sanctionDate = moment(createdOn).format("DD MMM YYYY");

  const obj=authUser?.role?.role;
  const roleId=authUser?.role?.roleId;

  const [privilegedata, setPrivilegeData] = useState([]);

  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllRolePrivilegeFlagsByRoleId?roleId=${roleId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);
        setPrivilegeData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card viewcase">
              <span id="taskheading3" className="mx-3 my-3">
                {viewCase?.customerName} -{" "}
                <span className=" text-white px-1" style={{backgroundColor:"#FDB714"}}>
                  InPrinciple
                </span>
              </span>
              <div className="row">
                <div className="col-sm-3">
                  <div className="card card11">
                    <span id="heading2">
                      Case Details
                      {
                        privilegedata[10]?.privilegeId === 111 && privilegedata[10]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip5}>
                        <img
                          src={editicon}
                          width="20"
                          height="20"
                          alt=""
                          className="edit1"
                          onClick={navigateToEditCaseDetails}
                        />
                      </Tooltip>:null
                      }
                    </span>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <span className="d-flex justify-content-start">
                            Case ID
                          </span>
                        </div>
                        <div className="col-md-8">{viewCase?.id}</div>
                       
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                            Lead Ref
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">{viewCase?.leadID}</div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                            Type
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">
                          {viewCase?.leadTypeVO?.leadType} -{" "}
                          {viewCase?.subTypesVO?.subTypes}
                        </div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                            GD Link
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">
                          <a
                            href={viewCase?.gdLink}
                            className="viewCaseGd"
                            target="_blank"
                          >
                            Click here
                          </a>
                        </div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                            Case Owner
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">
                          {viewCase?.ownerVO?.ownerName}
                        </div>
                        <div className="col-sm-4 mt-2">
                          <span className="d-flex justify-content-start">
                            Lead Initiator
                          </span>
                        </div>
                        <div className="col-sm-8 mt-2">
                          {viewCase?.initiatorVO?.initiatorName}
                        </div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                            Partner
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">{viewCase?.partnerVO?.partnerName}</div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                            channel Partner
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">{viewCase?.partnershipVO?.partnershipName}</div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                          Project
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">{viewCase?.projectName}</div>
                        
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                           Bank 
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">
                          {viewCase?.bankVO ?.bankName}
                        </div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                           Bank Branch
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">
                          {viewCase?.bankBranchVO?.bankBranchName}
                        </div>
                         <div className="col-sm-4 mt-2">
                          <span className="d-flex justify-content-start">
                            BM Name
                          </span>
                        </div>
                        <div className="col-sm-8 mt-2">
                        {viewCase?.bankBranchVO?.bankManagerName}
                        </div>
                        <div className="col-sm-4 mt-2">
                          <span className="d-flex justify-content-start">
                            DSA Code
                          </span>
                        </div>
                        <div className="col-sm-8 mt-2">
                         
                        </div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                            Sanction Amount
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">{viewCase?.sanctionAmount}</div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                          Sanction Date
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">{sanctionDate}</div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                          Disbursement Amount
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">{viewCase?.disbursementAmount}</div>
                        <div className="col-md-4 mt-2">
                          <span className="d-flex justify-content-start">
                           Disbursement Date
                          </span>
                        </div>
                        <div className="col-md-8 mt-2">{disbursementDate}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card card11">
                    <span
                      id="heading2"
                      // className="d-flex justify-content-start"
                    >
                      Applicant Details
                     
                      {
                        privilegedata[8]?.privilegeId === 109 && privilegedata[8]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip}>
                        <i
                        style={{width:'24px',height:"24px",marginTop:"-10px"}}
                          className="bi bi-plus-square-fill btn"
                          onClick={navigateToAddApplicant}
                        ></i>
                      </Tooltip>:null
                      }
                      {
                        privilegedata[10]?.privilegeId === 111 && privilegedata[10]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip6}>
                        <img
                          src={editicon}
                          style={{width:'18px',height:"18px",float:"right",marginRight:"10px"}}
                          alt=""
                          onClick={navigateToEditApplicant}
                        />
                      </Tooltip>:null
                      }
                     
                    </span>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6 mt-2">
                          <span className="d-flex justify-content-start">
                            No of Applicant
                          </span>
                        </div>
                        <div className="col-sm-6 mt-2">{applicant?.length}</div>
                        {/* <div className="col-sm-6 mt-2">
                                  <span className="d-flex justify-content-start">
                                    Applicant 1
                                  </span>
                                </div>
                                <div className="col-sm-6 mt-2 d-flex justify-content-start">
                                {viewCase?.customerName}
                                </div> */}
                      </div>
                      {applicant?.length > 0 ? (
                        <Fragment>
                          {applicant.map((apl, index) => {
                            return (
                              <div className="row" key={index}>
                                <div className="col-sm-6 mt-2">
                                  <span className="d-flex justify-content-start">
                                    Applicant {index + 1}
                                  </span>
                                </div>
                                <div className="col-sm-6 mt-2 d-flex justify-content-start">
                                  {apl?.applicantName}
                                </div>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card card11">
                    <span
                      id="heading2"
                      //className="d-flex justify-content-start"
                    >
                      Contact Details
                      
                      {
                        privilegedata[8]?.privilegeId === 109 && privilegedata[8]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip1}>
                        <i
                        style={{width:'24px',height:"24px",marginTop:"-10px"}}
                          className="bi bi-plus-square-fill btn"
                          onClick={navigateToAddContact}
                        ></i>
                      </Tooltip>:null
                      }
                      {
                        privilegedata[10]?.privilegeId === 111 && privilegedata[10]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip7}>
                        <img
                          src={editicon}
                          style={{width:'18px',height:"18px",float:"right",marginRight:"10px"}}
                          alt=""
                          onClick={navigateToEditContact}
                        />
                      </Tooltip>:null
                      }
                    </span>
                    <div className="card-body">
                      <div className="row">
                        {applicant?.length > 0 ? (
                          <Fragment>
                            {applicant.map((apl, index) => {
                              return (
                                <div className="row" key={index}>
                                  <span style={{fontWeight:"600"}}>
                                    Customer Contact Details
                                  </span>
                                  <div className="col-sm-6 mt-2">
                                    <span className="d-flex justify-content-start">
                                      {apl?.applicantName.split(" ")[0]} 1st Mo
                                      No
                                    </span>
                                  </div>
                                  <div className="col-sm-6 mt-2">
                                    {apl?.primaryNumber}
                                    <span className="mx-2" style={{fontSize:"18 px",color:"rgb(82, 221, 75)"}}>
                                      <i class="bi bi-whatsapp"></i>
                                    </span>
                                  </div>
                                  
                                  <div className="col-sm-6 mt-2">
                                    <span className="d-flex justify-content-start">
                                      {apl?.applicantName.split(" ")[0]} 2nd Mo
                                      No
                                    </span>
                                  </div>
                                  <div className="col-sm-6 mt-2 d-flex justify-content-start">
                                    {apl?.secondaryNumber}
                                  </div>
                                  <div className="col-sm-6 mt-2">
                                    <span className="d-flex justify-content-start">
                                      {apl?.applicantName.split(" ")[0]} Email
                                      ID
                                    </span>
                                  </div>
                                  <div className="col-sm-6 mt-2">
                                    {apl?.email}
                                  </div>
                                </div>
                              );
                            })}
                          </Fragment>
                        ) : null}
                        <div className="row mt-3">
                        <span style={{fontWeight:"600"}}>
                                    channel Partner Contact Details
                                  </span>
                                  <div className="col-sm-6 mt-2">
                                    <span className="d-flex justify-content-start">
                                    channel Partner 1st Mo
                                      No
                                    </span>
                                  </div>
                                  <div className="col-sm-6 mt-2">
                                    {viewCase?.partnershipVO?.mobileNumber}
                                    <span className="mx-2" style={{fontSize:"18 px",color:"rgb(82, 221, 75)"}}>
                                      <i class="bi bi-whatsapp"></i>
                                    </span>
                                  </div>
                                  <div className="col-sm-6 mt-2">
                                    <span className="d-flex justify-content-start">
                                    channel Partner Email
                                      ID
                                    </span>
                                  </div>
                                  <div className="col-sm-6 mt-2">
                                   {viewCase?.partnershipVO?.emailId}
                                  </div>
                                </div>
                                <div className="row mt-3">
                                <span style={{fontWeight:"600"}}>
                                    BM Contact Details
                                  </span>
                                  <div className="col-sm-6 mt-2">
                                    <span className="d-flex justify-content-start">
                                      BM Mobile Number
                                    </span>
                                  </div>
                                  <div className="col-sm-6 mt-2">
                                    {viewCase?.bankBranchVO?.bankManagerPrimaryNumber}
                                    <span className="mx-2" style={{fontSize:"18 px",color:"rgb(82, 221, 75)"}}>
                                      <i class="bi bi-whatsapp"></i>
                                    </span>
                                  </div>
                                  
                                  <div className="col-sm-6 mt-2">
                                    <span className="d-flex justify-content-start">
                                      BM Email
                                      ID
                                    </span>
                                  </div>
                                  <div className="col-sm-6 mt-2">
                                    {viewCase?.bankBranchVO?.bankManagerEmailID}
                                  </div>
                                </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="card card11">
                    <span
                      id="heading2"
                      //className="d-flex justify-content-start"
                    >
                      KYC Details
                      
                      {
                        privilegedata[8]?.privilegeId === 109 && privilegedata[8]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip2}>
                        <i
                        style={{width:'24px',height:"24px",marginTop:"-10px"}}
                          className="bi bi-plus-square-fill btn"
                          onClick={navigateToAddKYC}
                        ></i>
                      </Tooltip>:null
                      }
                       {
                        privilegedata[10]?.privilegeId === 111 && privilegedata[10]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip8}>
                        <img
                          src={editicon}
                          style={{width:'18px',height:"18px",float:"right",marginRight:"10px"}}
                          alt=""
                        />
                      </Tooltip>:null
                      }
                     
                    </span>
                    <div className="card-body">
                      <div className="row">
                        {/* <div className="col-sm-5 mt-2">
                          <span className="d-flex justify-content-start">
                            PAN
                          </span>
                        </div>
                        <div className="col-sm-7 mt-1">{customer?.pan}</div>
                        <div className="col-sm-5 mt-2">
                          <span className="d-flex justify-content-start">
                            AADHAR
                          </span>
                        </div>
                        <div className="col-sm-7 mt-2 d-flex justify-content-start">
                          {customer?.aadhar}
                        </div> */}
                        {applicant?.length > 0 ? (
                          <Fragment>
                            {applicant.map((apl, index) => {
                              return (
                                <div className="row" key={index}>
                                  <div className="col-sm-5 mt-2">
                                    <span className="d-flex justify-content-start">
                                      {apl?.applicantName.split(" ")[0]} PAN
                                    </span>
                                  </div>
                                  <div className="col-sm-7 mt-2 mx-0">
                                    {apl?.panNumber}
                                  </div>
                                  <div className="col-sm-5 mt-2">
                                    <span className="d-flex justify-content-start">
                                      {apl?.applicantName.split(" ")[0]} AADHAR
                                    </span>
                                  </div>
                                  <div className="col-sm-7 mt-2 d-flex justify-content-start">
                                    {apl?.aadharNumber}
                                  </div>
                                </div>
                              );
                            })}
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="row  mt-3">
                    <div className="col-md-3">
                      <span
                        id="taskheading3"
                        // className="d-flex justify-content-start mx-3"
                      >
                        Address Details
                        
                        {
                        privilegedata[8]?.privilegeId === 109 && privilegedata[8]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip3}>
                          <i
                          style={{width:'24px',height:"24px",marginTop:"-15px"}}
                            className="bi bi-plus-square-fill btn"
                            onClick={navigateToAddAddress}
                          ></i>
                        </Tooltip>:null
                      }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-3">
                  <div className="card card10">
                    <span id="heading2">
                      Permanent Address
                      
                      {
                        privilegedata[10]?.privilegeId === 111 && privilegedata[10]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip9}>
                        <img
                          src={editicon}
                          style={{width:'18px',height:"18px",float:"right",marginRight:"10px"}}
                          alt=""
                          onClick={navigateToEditPermanentAddress}
                        />
                      </Tooltip>:null
                      }
                    </span>
                    <div className="card-body">
                      {customer?.pAddressLine1} {customer?.pAddressLine2}{" "}
                      {customer?.pLandmark} <br></br>
                      {customer?.prmntCity} - {customer?.pPIN} <br></br>
                      {customer?.prmntState},
                      {customer?.prmntCountry}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card card10">
                    <span id="heading12">
                      Current Address
                      {
                        privilegedata[10]?.privilegeId === 111 && privilegedata[10]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip20}>
                        <img
                          src={editicon}
                          width="20"
                          height="20"
                          alt=""
                          className="edit1"
                          onClick={navigateToEditCurrentAddress}
                        />
                      </Tooltip>:null
                      }
                    </span>

                    <div className="card-body">
                      {customer?.cAddressLine1} {customer?.cAddressLine2}{" "}
                      {customer?.cLandmark} <br></br>
                      {customer?.correspondCity} - {customer?.cPIN} <br></br>
                      {customer?.correspondState} ,{" "}
                      {customer?.correspondsCountry}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card card10">
                    <span id="heading12">
                      Site Address
                     
                      {
                        privilegedata[10]?.privilegeId === 111 && privilegedata[10]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip10}>
                        <img
                          src={editicon}
                          style={{width:'18px',height:"18px",float:"right",marginRight:"10px"}}
                          alt=""
                          onClick={navigateToEditSiteAddress}
                        />
                      </Tooltip>:null
                      }
                    </span>

                    <div className="card-body">
                      {viewCase?.siteAddressLine1} {viewCase?.siteAddressLine2}{" "}
                      {viewCase?.siteLandmark} <br></br>
                      {viewCase?.siteCity} - {viewCase?.sitePin}{" "}
                      <br></br>
                      {viewCase?.siteState},{" "}
                      {viewCase?.siteCountry}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card card10">
                    <span id="heading12">
                      Office/Business Address
                      {
                        privilegedata[10]?.privilegeId === 111 && privilegedata[10]?.privilageFlag === true?
                        <Tooltip placement="right" overlay={renderTooltip11}>
                        <img
                          src={editicon}
                          width="20"
                          height="20"
                          alt=""
                          className="edit5"
                          onClick={navigateToEditOfficeAddress}
                        />
                      </Tooltip>:null
                      }
                    </span>
                    <div className="card-body">
                      {customer?.oAddressLine1} {customer?.oAddressLine2}{" "}
                      {customer?.oLandmark} <br></br>
                      {customer?.officeCity} - {customer?.oPIN} <br></br>
                      {customer?.officeState}{" "}
                      {customer?.officeCountry}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-2">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <span
                        id="taskheading3"
                        className="d-flex justify-content-start mx-3"
                      >
                        Documents(03)
                        <Tooltip placement="right" overlay={renderTooltip4}>
                          <i
                          style={{width:'24px',height:"24px",marginTop:"-6px"}}
                            className="bi bi-plus-square-fill btn"
                            onClick={navigateToAddDocuments}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card card33">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-2">
                            <Tooltip
                              placement="right"
                              overlay={renderTooltip12}
                            >
                              <h5 id="trashbtn1">
                                <i class="bi bi-trash  btn-lg"></i>
                              </h5>
                            </Tooltip>
                            <img
                              src={pdfimg}
                              width="64"
                              height="79"
                              alt=""
                              className="mx-3"
                            />
                            <div className="mt-2">Address Proof</div>
                            <div>Aadhar Card</div>
                          </div>
                          <div className="col-md-2">
                            <Tooltip
                              placement="right"
                              overlay={renderTooltip12}
                            >
                              <h5 id="trashbtn1">
                                <i class="bi bi-trash  btn-lg"></i>
                              </h5>
                            </Tooltip>
                            <img
                              src={pdfimg}
                              width="64"
                              height="79"
                              alt=""
                              className="mx-3"
                            />
                            <div className="mt-2">Address Proof</div>
                            <div>Utility Bill</div>
                          </div>
                          <div className="col-md-2">
                            <Tooltip
                              placement="right"
                              overlay={renderTooltip12}
                            >
                              <h5 id="trashbtn1">
                                <i class="bi bi-trash  btn-lg"></i>
                              </h5>
                            </Tooltip>
                            <img
                              src={leftChevron}
                              width="64"
                              height="79"
                              alt=""
                              className="mx-3"
                            />
                            <div className="mt-2">KYC</div>
                            <div>Company ID Card</div>
                          </div>
                        </div>
                      </div>
                      <span className="mx-3">
                        Double Click to open a document{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Container fluid className="mt-5">
      {viewCase.template?.templateId === null ||
        viewCase.template?.templateId === undefined ||
        viewCase.template?.templateId === "" ? (
          <row>
          <Col lg={12}>
            <div className="text-light bg-primary text-center p-2 mt-4 viewcase">
              Task Details{" "}
              <Tooltip placement="right" overlay={renderTooltip21}>
                <i
                  className="bi bi-plus-square-fill btn btn-sm"
                  onClick={navigateToAddTask}
                ></i>
              </Tooltip>
            </div>
          </Col>
        </row>
        ):(
          <row>
          <Col lg={12}>
            <div className="text-light bg-primary text-center p-2 mt-4 viewcase">
              Task Details{" "}
              {/* <Tooltip placement="right" overlay={renderTooltip21}>
                <i
                  className="bi bi-plus-square-fill btn btn-sm"
                  onClick={navigateToAddTask}
                ></i>
              </Tooltip> */}
            </div>
          </Col>
        </row>
        )}
        

        {viewCase?.template?.templateId === null ||
        viewCase.template?.templateId === undefined ||
        viewCase?.template?.templateId === "" ? (
          <DataTable
            value={products}
            expandedRows={expandedRows}
            selectionMode="single"
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={rowExpansionTemplate}
            onRowCollapse={""}
            rowExpansionTemplate={expandedTemplate}
            dataKey="taskId"
            size="small"
            resizeable={false}
            style={{ width: "1450px" }}
          >
            <Column
              //  field="1"
              header="Sr No"
              body={(rowData, column) => (
                <UncontrolledDropdown>
                  {/* const row=1 */}
                  {column.rowIndex + 1}
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-light btn-sm"
                    tag="button"
                  >
                    <img
                      src={setting}
                      width="15"
                      height="15"
                      alt=""
                      className="mx-4"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#">
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                      Delete Task
                    </DropdownItem>
                    <DropdownItem href="">
                      <i
                        className="ri-eye-fill align-bottom me-2 text-muted"
                        onClick={() =>
                          navigateToCreateAboveTask(
                            rowData.sequenceNo,
                            rowData.loanCaseID
                          )
                        }
                      >
                        {" "}
                        <span> Create Task Above</span>{" "}
                      </i>
                    </DropdownItem>
                    <DropdownItem href="">
                      <i
                        className="ri-eye-fill align-bottom me-2 text-muted"
                        onClick={() =>
                          navigateToCreateBelowTask(
                            rowData.sequenceNo,
                            rowData.loanCaseID
                          )
                        }
                      >
                        {" "}
                        <span> Create Task Below</span>{" "}
                      </i>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            />
            <Column
              field="generatedId"
              header="Task ID"
              body={navigateToViewTask}
            />
            <Column field="stage.loanStage" header="Stage" />
            <Column expander={allowExpansion} style={{ width: "10px" }} />
            <Column field="taskName" header="Task Name" />
            <Column field="userVO.userName" header="Case Owner" />
            <Column
              // field="taskStatus.taskStatus"
              header="Task Status"
              body={statusTemplate}
            />
            <Column
              field="startDate"
              header="Start Date"
              body={startDateTemplate}
            />
            <Column
              field="plannedEndDate"
              header="Planned End Date"
              body={plannedDateTemplate}
            />
            <Column
              field="duration"
              header="Duration"
              body={durationTemplate}
            />
           {/* <Column field="loggedUser.userName" header="Assign By" /> */}
            <Column
              field="endDate"
              header="Actual End Date"
              body={endDateTemplate}
            />
          </DataTable>
        ) : (
          <DataTable
            value={tempData}
            expandedRows={""}
            selectionMode="single"
            onRowToggle={""}
            onRowExpand={""}
            onRowCollapse={""}
            rowExpansionTemplate={""}
            dataKey="templateTaskId"
            size="small"
            resizeable={false}
            style={{ width: "1450px" }}
          >
            <Column
              //  field="1"
              header="Sr No"
              body={(rowData, column) => column.rowIndex + 1}
            />
            {/* <Column field="id" header="ID" /> */}
            <Column field="loanStage.loanStage" header="Stage" />
            <Column field="loanTaskVO.loanTask" header="Task" />
            <Column field="subTaskVO.subTaskName" header="Sub Task" />
            
            <Column header="Case Owner" body={CaseOwner} />
            <Column
              // field="taskStatus.taskStatus"
              header="Task Status"
              body={statusTemp}
            />
            <Column
              // field="startDate"
              header="Start Date"
              body={startDateTemp}
            />
            <Column
              // field="plannedEndDate"
              header="Planned End Date"
              body={plannedDateTemp}
            />
            <Column
              field="duration"
              header="Duration"
              body={durationTemplate}
            />
            {/* <Column  header="Assign By" body={assingByTemp}/> */}
            <Column
              field="endDate"
              header="Actual End Date"
              body={endDateTemp}
            />
          </DataTable>
        // <div className="container-fluid">
        //    <div className="row">
        //     <div className="col-lg-12">
        //       <div className="card" style={{width:"1420px"}}>
        //       <TableContainer
        //   columns={columns}
        //   data={tempData}
        //   isGlobalFilter={false}
        //   isAddUserList={false}
        //   customPageSize={5}
        //   className="custom-header-css"
        //   divClass="table-responsive table-card mb-0"
        //   tableClass="align-middle table-nowrap"
        //   theadClass="table-secondary"
        //   isLeadsFilter={false}
        //   SearchPlaceholder="Search for"
        //   highlightOnHover
        // />
        //       </div>
        //     </div>
        //    </div>
        // </div>
         )}
      </Container>
      <Container fluid className="mt-5">
        <row>
          <Col lg={12}>
            <div>
              <Toast ref={toast} />
            </div>
          </Col>
        </row>
      </Container>
    </Fragment>
  );
}

