import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
import "../../../assets/scss/pages/lead.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import TableContainer from '../../../Components/Common/TableContainer';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import { Pagination } from 'react-bootstrap';
import moment from 'moment';
import * as XLSX from "xlsx";
import excelIcon from "../../../assets/images/excel.png";
export default function Customersmgmt() {


    var renderTooltip = <span>Create Customer</span>;
    var renderTooltipexcel = <span>Export Data</span>;
    const navigate = useNavigate();
  const navigateToCreateLead = () => {
    // 👇️ navigate to /CreateLead
    navigate('/AddNewCustomer');
  };
  
  
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
    const[dataAll,setDataAll]=useState([]);
     
   
    const loadLead = async (value) => {
      if (value != null) {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/customer/getAllCustomer?pageNumber=`+page+`&pageSize=`+value)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      } else {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/customer/getAllCustomer?pageNumber=`+page+`&pageSize=`+showRecord)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      }
    };
   
    useEffect(() => {
      loadLead();
    }, []);

    const [showRecord, setshowRecord] = useState(10);

    const  handleChanegeShowRec = (e) => {
      const { name, value, type, checked } = e.currentTarget;
   
      setshowRecord(e.currentTarget.value);
  
      loadLead(value);
     }
    //for table records per page
    const [recordCount, setrecordCount] = useState(10);
   
   
    //for pagination
   
    const [pageData, setPageData] = useState([]);
   const [page, setPage] = useState(1);
   const [pageCount, setPageCount] = useState(10);
   // handle next
   const handleNext = () => {
      if (page === pageCount) return page;
      setPage(page + 1)
   }
   
   // handle previous
   const handlePrevios = () => {
      if (page === 1) return page;
      setPage(page - 1)
   }
   
   useEffect(() => {
    loadLead()
   }, [page])
   
   useEffect(() => {
      const pagedatacount = Math.ceil(dataAll?.length / showRecord);
      setPageCount(pagedatacount);
   
      if (page) {
          const LIMIT = showRecord;
          const skip = LIMIT * page // 5 *2 = 10
           const dataskip = dataAll?.slice(page === 1 ? 0 : skip - LIMIT, skip);
          setPageData(dataskip)
      }
   }, [search,dataAll])


    useEffect(() => {
      const result=data.filter(leads=>{
        return leads.customerName.toLowerCase().match(search.toLowerCase())
      });
      setFilterLeads(result);
    }, [search]);
   

    const createdDate=(dataAll)=>{
      return moment(dataAll.dob)
      .format("DD-MMM-YYYY")
    }


    const columns = useMemo(
      () => [
        {
          Header: "Customer ID",
          Cell: (data) => {
            return (
              <Link id='leadid1' to={`/ViewCustomer/${data?.row.original.customerId}`} >{data?.row.original.generatedID}</Link>
            )}
        },
        {
          Header: "DOB",
          accessor: (d) => {
            return moment(d.dob)
              .format("DD-MMM-YYYY")
          },
          filterable: false
        },
        
        {
          Header: "Customer Name",
          accessor: "customerName",
          filterable: false
        },
        {
          Header: "Mobile Number",
          accessor: "primaryNumber",
          filterable: false
        },
        {
          Header: "Customer Type",
          accessor: "customerType",
          filterable: false
        },
        {
          Header: "Product",
        },
        {
          Header: "Insurance",
        },
        {
          Header: "Open Lead",
        }, 
      ],
      []
    );

const exportToExcel = () => {
  const filteredLeadData = filterLeads.map((data) => ({
    "Id": data.id,
    "Date": data.dob,
    "Customer Name": data.customerName,
    "Mobile Number": data.primaryNumber,
    "Lead Type": data.typesVO?.leadTypeVO?.leadType,
    "Sub Type": data.typesVO?.type,
    "Customer Type":"data.customerType",

    "email": data.emailID,
    "Created Date": data.createdDate,
  }));
  
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(filteredLeadData);
  XLSX.utils.book_append_sheet(wb, ws, "Customers");

  const todayDate = moment().format("YYYY-MM-DD");
  const filename = `Customers_${todayDate}.xlsx`;

  // Ensure the correct MIME type and download
  XLSX.writeFile(wb, filename, { bookType: "xlsx", type: "binary" });
};


    const authUser = JSON.parse(localStorage.getItem("user"));
    const obj=authUser?.role?.role;
    const roleId=authUser?.role?.roleId;

    const [privilegedata, setPrivilegeData] = useState([]);

    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getAllRolePrivilegeFlagsByRoleId?roleId=${roleId}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
          setPrivilegeData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  return (
    <Fragment>
        <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
                    {
                      privilegedata[4]?.privilegeId === 105 && privilegedata[4]?.privilageFlag === true?<Fragment>
                       <div className='d-inline-block'>
                <h3> Customers</h3>
              </div>
              <div className='d-inline-block mx-1'>
              <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={navigateToCreateLead} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip>
              </div>
                      </Fragment>:null
                    }
              
            </div>
              </Col>
              <Col sm={5}></Col>
                  <Col sm={8} style={{marginTop:"0px", paddingLeft:"30px"}}>
                  <div className='' style={{float:'left'}}>
           <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
           value={showRecord}
                     className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                     <option  value="10">Show 10</option>
                     <option value="25">Show 25</option>
                     <option value="50">Show 50</option>
                     <option value="100">Show 100</option>
   
                     </select>
                     </div>
                  </Col>
                  {/* <Col sm={1}></Col> */}
                  <Col sm={1} style={{marginTop:"0px"}}>
                  <div className="d-inline-block">
                        <Tooltip placement="left" overlay={renderTooltipexcel}>
                          {/* <h2>
                            <i
                              onClick={navigateToCreateLead}
                              className="bi bi-file-excel btn btn-lg"
                            ></i>{" "}

                          </h2> */}
                           <img src={excelIcon} width = "35" height = "30" alt='' className='mx-1' onClick={exportToExcel} style={{cursor:"pointer"}}/>
                        </Tooltip>
                      </div>
                  </Col>
                  <Col sm={3} style={{marginTop:"0px",paddingRight:"30px"}}>
                
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder="Search for..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                 dataAll?.length > 0 && dataAll?.deleteFlag!==null?(
                    <>
                    <div className="container-fluid">
                 <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX:"auto",height:"600px"}}
                    id="tableData">
                      <table className="table align-items-center table-flush table-hover" id="dataTableHover">
                        <thead className="tableheader">
                        <tr>    
          <th scope="col" className="tableroww">Sr.No.</th>
       <th scope="col" className="tableroww">Customer ID</th>
         <th scope="col" className="tableroww">DOB</th>
         <th scope="col" className="tableroww">Customer Name</th>
         <th scope="col" className="tableroww">Mobile Number</th>
         <th scope="col" className="tableroww">Customer Type</th>
         {/* <th scope="col" className="tableroww">Product</th> */}
         {/* <th scope="col" className="tableroww">Insurance</th> */}
         <th scope="col" className="tableroww">Open Lead</th>
       </tr>
     </thead>
     <tbody>
      
       {
           pageData?.map((dataAll,index)=>( 
               <tr key={index}>
               <th scope='row' className="tableroww1">{index + 1}</th>
              
               {
                privilegedata[5]?.privilegeId === 106 && privilegedata[5]?.privilageFlag === true?<Fragment>
 <td className="tableroww1 capitalizetxxxt">
               <Link id="leadid1" to={`/ViewCustomer/${dataAll.customerId}`} >
         {dataAll.generatedID}
            </Link>
               </td>
                </Fragment>:<Fragment>
                <td className="tableroww1 capitalizetxxxt">
               <Link id="leadid1">
         {dataAll.generatedID}
            </Link>
               </td><td className="tableroww1 capitalizetxxxt">
               <Link id="leadid1" >
         {dataAll.id}
            </Link>
               </td>
                </Fragment>
               }
               <td className="tableroww1 capitalizetxxxt">{createdDate(dataAll)}</td>
                <td className="tableroww1 capitalizetxxxt">{dataAll.customerName}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll.primaryNumber}</td>
               <td className="tableroww1 capitalizetxxxt">{dataAll.customerType}</td>
               {/* <td className="tableroww1 capitalizetxxxt">{""}</td> */}
               {/* <td className="tableroww1 capitalizetxxxt">{""}</td> */}
               <td className="tableroww1 capitalizetxxxt">{""}</td>
               </tr>
   
   
            ))
       } 
     </tbody>
       </table>
       {dataAll?.length > 0 && (
                      <div className="" style={{ float: "right" }}>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => handlePrevios()}
                            disabled={page === 1}
                          />
                          {Array(pageCount)
                            .fill(null)
                            .map((ele, index) => {
                              return (
                                <>
                                  <Pagination.Item
                                    active={page === index + 1 ? true : false}
                                    onClick={() => setPage(index + 1)}
                                  >
                                    {index + 1}
                                  </Pagination.Item>
                                </>
                              );
                            })}
                          <Pagination.Next
                            onClick={() => handleNext()}
                            disabled={page === pageCount}
                          />
                        </Pagination>
                      </div>
                    )}
                            </div>
                  </div>
                 </div>
                 </div>
                     </>
                   ):<>
                   <div>No Records Available</div></>
                }
                  </div>
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </Fragment>
  )
}
